import { useMutation, useQueryClient } from "@tanstack/react-query";

const handleUpdateFaq = async (
  venueId: string,
  faqId: string,
  body: { question: string; answer: string }
): Promise<boolean> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/venues/${venueId}/faqs/${faqId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );

  if (!response.ok) {
    throw new Error("Unable to acknowledge the venue");
  }

  return true;
};

export const useUpdateFaq = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      venueId,
      faqId,
      question,
      answer,
    }: {
      venueId: string;
      faqId: string;
      question: string;
      answer: string;
    }) => handleUpdateFaq(venueId, faqId, { question, answer }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["venue-faqs"]);
      },
    }
  );
};
