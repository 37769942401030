import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Booking } from "../../types/booking";
import { identity, pickBy } from "lodash";

const handleAlternativeRequest = async (
  requestId: string,
  confirmedTime: string,
  date?: string
): Promise<Booking> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/bookings/${requestId}/alternative`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        pickBy(
          {
            confirmed_time: confirmedTime,
            date: date,
          },
          identity
        )
      ),
    }
  );

  if (!response.ok) {
    throw new Error("Unable to accept booking request");
  }

  const data = (await response.json()).booking;
  return data;
};

export const useAlternativeRequest = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      requestId,
      confirmedTime,
      date,
    }: {
      requestId: string;
      confirmedTime: string;
      date?: string;
    }) => handleAlternativeRequest(requestId, confirmedTime, date),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["booking"]);
        queryClient.invalidateQueries(["current-bookings"]);
        queryClient.invalidateQueries(["requested-bookings"]);
      },
    }
  );
};
