import dayjs from "dayjs";
import { Booking } from "../../types/booking";
import { BookingStatus } from "../../transforms/status";
import { getCleanTime } from "../../transforms/time";

const bgColorMap = {
  [BookingStatus.BOOKING_PENDING]: "border-indigo-500",
  [BookingStatus.BOOKING_VENUE_ACCEPTS]: "border-indigo-500",
  [BookingStatus.BOOKING_VENUE_ALTERNATIVE]: "border-indigo-500",
  [BookingStatus.BOOKING_VENUE_DECLINED]: "border-red-500",
  [BookingStatus.BOOKING_INFLUENCER_DECLINED]: "border-indigo-500",
  [BookingStatus.BOOKING_INFLUENCER_CONFIRMS]: "border-green-500",
  [BookingStatus.BOOKING_INFLUENCER_CANCELLED]: "border-red-500",
  [BookingStatus.BOOKING_VENUE_CANCELLED]: "border-red-500",
  [BookingStatus.BOOKING_COMPLETED]: "border-green-900",
};

interface Props {
  d: dayjs.Dayjs;
  date: string;
  bookings: Booking[];
  onSelect: (id: string) => void;
  isOutside?: boolean;
}

export const Day = ({ d, date, bookings, onSelect, isOutside }: Props) => {
  return (
    <button
      key={date}
      type="button"
      disabled={!bookings.length}
      onClick={() => onSelect(bookings[0].request_id)}
      className={`bg-ms-black py-2 h-32 text-left overflow-hidden ${
        isOutside ? "opacity-75" : ""
      }`}
    >
      <p className="text-white text-sm text-center">{d.format("DD")}</p>
      <div className="mt-2">
        {bookings.slice(0, 2).map((booking) => (
          <div
            className={`w-full ${
              bgColorMap[booking.status]
            } border rounded-xl py-1.5 flex items-center justify-between px-2`}
          >
            <span className="text-white text-xs">{`${booking.influencer.first_name} ${booking.influencer.last_name}`}</span>
            <span className="text-white text-xs">
              {getCleanTime(booking.confirmed_time)}
            </span>
          </div>
        ))}
        {bookings.length > 2 ? (
          <p className="text-white text-xs ml-2 mt-1">{`+${
            bookings.length - 2
          } others`}</p>
        ) : null}
      </div>
    </button>
  );
};
