import { useMemo, useState } from "react";
import { Card } from "./Card";
import { VenueDrawer } from "./VenueDrawer";
import { useGetVenues } from "../../hooks/queries/useGetVenues";
import { useGetUser } from "../../hooks/queries/useGetUser";
import { sortBy } from "lodash";

export const Venues = () => {
  // const { data: venues = [] } = useGetVenues();
  const { data: { venues } = { venues: [] } } = useGetUser();
  const [activeVenueId, setActiveVenueId] = useState<string | undefined>(
    undefined
  );

  const shownVenues = useMemo(() => {
    return sortBy(venues, "name");
  }, [venues]);

  return (
    <>
      <div className="px-16 py-12">
        <div className="w-full max-w-xl">
          <h2 className="text-white text-xl">Venues</h2>
          <p className="text-ms-lt-gray text-sm font-light mt-1">
            Click on a venue to update its offer, hours, photos, and
            description.
          </p>

          <div className="mt-6 space-y-3">
            {shownVenues.map((venue) => (
              <Card
                key={venue.id}
                venue={venue}
                onSelect={() => setActiveVenueId(venue.id)}
              />
            ))}
          </div>

          <p className="mt-4 text-white text-sm">
            Want to add a new venue?{" "}
            <a href="#" className="underline">
              Contact us
            </a>
          </p>
        </div>
      </div>

      <VenueDrawer
        venueId={activeVenueId}
        onClose={() => setActiveVenueId(undefined)}
      />
    </>
  );
};
