import { useGetBookingRequestedTime } from "../../hooks/custom/useGetBookingRequestedTime";
import { useAcceptRequest } from "../../hooks/mutations/useAcceptRequest";
import { Booking } from "../../types/booking";

interface Props {
  booking: Booking;
  onSuccess?: () => void;
}

export const AcceptButton = ({ booking, onSuccess }: Props) => {
  const { mutateAsync: mutateAccept } = useAcceptRequest();
  const requestedTime = useGetBookingRequestedTime(booking);

  const handleAccept = async () => {
    await mutateAccept({
      requestId: booking.request_id!,
      confirmedTime: requestedTime!,
    });
    onSuccess?.();
  };

  return (
    <button
      onClick={handleAccept}
      className="mt-4 rounded-full w-full h-14 bg-ms-primary text-ms-black font-medium text-sm"
    >
      {`Accept ${requestedTime}`}
    </button>
  );
};
