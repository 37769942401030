import {
  AnimatePresence,
  HTMLMotionProps,
  MotionProps,
  motion,
  useDragControls,
} from "framer-motion";
import { Booking } from "../../types/booking";
import { Portal } from "react-portal";
import { useEffect, useMemo, useRef, useState } from "react";

const timesMap = {
  breakfast: [
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
  ],
  lunch: [
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
  ],
  dinner: [
    "5:30 PM",
    "6:00 PM",
    "6:30 PM",
    "7:00 PM",
    "7:30 PM",
    "8:00 PM",
    "8:30 PM",
    "9:00 PM",
  ],
  late: ["9:00 PM", "9:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"],
} as const;

interface AlternativeTrayProps {
  isVisible: boolean;
  booking: Booking | undefined;
  onClose: () => void;
  onPropose: (value: string) => void;
}

export const AlternativeTray = ({
  isVisible,
  booking,
  onClose,
  onPropose,
}: AlternativeTrayProps) => {
  const timeOptions = useMemo(() => {
    return [
      ...(!!booking?.requested_breakfast ? timesMap.breakfast : []),
      ...(!!booking?.requested_lunch ? timesMap.lunch : []),
      ...(!!booking?.requested_dinner ? timesMap.dinner : []),
      ...(!!booking?.requested_late ? timesMap.late : []),
    ];
  }, [booking]);

  const [selectedTime, setSelectedTime] = useState<string | undefined>(
    undefined
  );
  const controls = useDragControls();

  useEffect(() => {
    if (!isVisible) return;

    const selected =
      booking?.requested_dinner ||
      booking?.requested_lunch ||
      booking?.requested_breakfast ||
      booking?.requested_late;

    setSelectedTime(selected || undefined);
  }, [isVisible]);

  return (
    <Portal>
      <AnimatePresence>
        {isVisible ? (
          <div className="fixed top-0 left-0 right-0 bottom-0">
            <motion.button
              onClick={onClose}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute top-0 left-0 right-0 bottom-0 z-20 bg-black bg-opacity-25"
            />

            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: isVisible ? 0 : 1000 }}
              exit={{ y: 1000 }}
              drag="y"
              dragConstraints={{ top: 0 }}
              transition={{
                type: "tween",
                duration: 0.4,
              }}
              dragControls={controls}
              dragSnapToOrigin={true}
              className="bg-ms-dk-gray rounded-t-3xl py-4 px-4 absolute bottom-0 left-0 right-0 w-full max-w-xl mx-auto z-50"
            >
              <div className="w-full mx-auto h-1 rounded-full max-w-[25%] bg-ms-md-gray mb-8"></div>
              <div className="flex flex-col items-center justify-center">
                <img
                  className="h-16 w-16 rounded-full"
                  src={booking?.influencer.photo_url ?? ""}
                />
                <p className="text-ms-white text-sm font-medium mt-4">{`Propose a different time for ${booking?.influencer.first_name}`}</p>
              </div>

              <div className="flex flex-wrap mt-8">
                {timeOptions.map((option) => (
                  <button
                    type="button"
                    key={option}
                    onClick={() => setSelectedTime(option)}
                    className={`${
                      option === selectedTime
                        ? "bg-ms-primary"
                        : "bg-ms-md-gray"
                    } py-3 px-2 ${
                      option === selectedTime ? "bg-ms-black" : "text-ms-white"
                    } mr-2 mb-2 rounded`}
                  >
                    {option}
                  </button>
                ))}
              </div>

              <div className="mt-8 mb-8 space-y-3">
                <button
                  onClick={() => {
                    onPropose(selectedTime!);
                    onClose();
                  }}
                  className="h-16 w-full rounded-full bg-ms-primary flex items-center justify-center text-ms-black font-semibold"
                >
                  {`Send invite for ${selectedTime}`}
                </button>
                <button
                  onClick={onClose}
                  className="h-16 w-full rounded-full bg-transparent border border-ms-primary flex items-center justify-center text-ms-primary font-semibold"
                >
                  Cancel
                </button>
              </div>
            </motion.div>
          </div>
        ) : null}
      </AnimatePresence>
    </Portal>
  );
};
