import React, { useMemo, useState } from "react";
import dayjs from "dayjs";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { groupBy } from "lodash";
import { Day } from "./Day";
import { Booking } from "../../types/booking";

interface Props {
  bookings: Booking[];
  onSelect: (id: string) => void;
}

export const RequestCalendar = ({ bookings, onSelect }: Props) => {
  const [month, setMonth] = useState(dayjs().startOf("month"));

  const startDay = useMemo(() => month.startOf("month").day(), [month]);
  const shownCurrentDays = useMemo(
    () =>
      Array.from({ length: month.daysInMonth() }, (_, i) =>
        month.add(i, "day")
      ).map((d) => ({
        d,
        date: d.format("YYYY-MM-DD"),
      })),
    [month]
  );

  const previousMonth = useMemo(() => month.subtract(1, "months"), [month]);
  const shownPreviousDays = useMemo(
    () =>
      Array.from({ length: startDay }, (_, i) =>
        previousMonth.endOf("month").subtract(i, "days")
      )
        .reverse()
        .map((d) => ({
          d,
          date: d.format("YYYY-MM-DD"),
        })),
    [previousMonth]
  );

  const nextMonth = useMemo(() => month.add(1, "months"), [month]);
  const shownNextDays = useMemo(
    () =>
      Array.from(
        {
          length:
            (shownPreviousDays.length + shownCurrentDays.length > 35
              ? 42
              : 35) -
            shownPreviousDays.length -
            shownCurrentDays.length,
        },
        (_, i) => nextMonth.startOf("month").add(i, "days")
      ).map((d) => ({
        d,
        date: d.format("YYYY-MM-DD"),
      })),
    [previousMonth]
  );

  const indexedBookings = useMemo(() => groupBy(bookings, "date"), [bookings]);

  return (
    <div>
      <div className="w-full flex justify-end mb-2">
        <div className="flex space-x-4 items-center">
          <button onClick={() => setMonth(month.subtract(1, "month"))}>
            <ChevronLeftIcon className="h-4 w-4 text-white" />
          </button>
          <span className="text-white">{month.format("MMMM YYYY")}</span>
          <button onClick={() => setMonth(month.add(1, "month"))}>
            <ChevronRightIcon className="h-4 w-4 text-white" />
          </button>
        </div>
      </div>

      <div className="bg-ms-lt-gray bg-opacity-25 border border-ms-lt-gray border-opacity-25">
        <div className="grid grid-cols-7 grid-rows-5 gap-px">
          {shownPreviousDays.map((day) => (
            <Day
              key={day.date}
              {...day}
              bookings={indexedBookings[day.date] ?? []}
              onSelect={onSelect}
              isOutside
            />
          ))}
          {shownCurrentDays.map((day) => (
            <Day
              key={day.date}
              {...day}
              bookings={indexedBookings[day.date] ?? []}
              onSelect={onSelect}
            />
          ))}
          {shownNextDays.map((day) => (
            <Day
              key={day.date}
              {...day}
              bookings={indexedBookings[day.date] ?? []}
              onSelect={onSelect}
              isOutside
            />
          ))}
        </div>
      </div>
    </div>
  );
};
