import { useMemo, useRef, useState } from "react";
import { RequestCard } from "./RequestCard";
import { ReservationsTable } from "../../components/ReservationsTable";
import { RequestDrawer } from "../../components/RequestDrawer";

import mockData from "../../mocks/reservations.mock";
import { useGetCurrentBookings } from "../../hooks/queries/useGetCurrentBookings";
import { useGetRequests } from "../../hooks/queries/useGetRequests";
import { useGetUser } from "../../hooks/queries/useGetUser";
import { VenueChoice } from "../../components/VenueChoice";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

export const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: currentBookings = [] } = useGetCurrentBookings();
  const { data: pendingRequests = [] } = useGetRequests();

  const hasViewQueryParam = useRef(false);

  const pendingRequestIds = useMemo(
    () =>
      pendingRequests
        .filter((request) => !request.is_blurred)
        .map((request) => request.request_id),
    [pendingRequests]
  );

  const [activeRequestId, setActiveRequestId] = useState<string | undefined>(
    undefined
  );
  const [selectedVenue, setSelectedVenue] = useState("*");
  const activeRequest = useMemo(() => {
    return activeRequestId ? {} : null;
  }, [activeRequestId]);

  const todayReservations = useMemo(() => {
    const today = dayjs().format("YYYY-MM-DD");
    return currentBookings
      .filter((booking) =>
        selectedVenue === "*" ? true : booking.venue_id === selectedVenue
      )
      .filter((booking) => booking.date === today);
  }, [currentBookings, selectedVenue]);

  return (
    <>
      <div className="px-4 md:px-16 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-x-0 gap-y-8 md:gap-x-24 md:gap-y-16">
          <div className="col-span-2">
            <h2 className="text-white text-xl">Today's reservations</h2>
            <VenueChoice selected={selectedVenue} onSelect={setSelectedVenue} />

            {todayReservations.length ? (
              <ReservationsTable
                bookings={todayReservations}
                onSelect={setActiveRequestId}
              />
            ) : (
              <div className="w-full flex items-center justify-center h-64">
                <p className="text-ms-lt-gray text-sm text-center font-light">
                  No reservations today
                </p>
              </div>
            )}
          </div>

          <div className="col-span-1">
            <h2 className="text-white text-xl">Guides</h2>
            <p className="text-white text-sm font-thin mt-1">
              Learn how to build a strong profile, get more bookings, and be the
              best host you can be.
            </p>
            <div className="mt-8 p-4 border border-ms-lt-gray rounded-xl space-y-4 text-white underline text-sm">
              <a
                className="block"
                target="_blank"
                rel="noopener noreferrer"
                href="https://midi-morocco-1f2.notion.site/Tips-for-hosting-2f7c182cb4634e09841af00261aa6cd4?pvs=25"
              >
                Tips for hosting
              </a>
              <a
                className="block"
                target="_blank"
                rel="noopener noreferrer"
                href="https://midi-morocco-1f2.notion.site/Managing-a-booking-05c49179ee7f4dadb5830c124e6a5a38"
              >
                Managing a booking
              </a>
              <a
                className="block"
                target="_blank"
                rel="noopener noreferrer"
                href="https://midi-morocco-1f2.notion.site/Editing-your-profile-b09fe8871ffe4f9b8b9d2f90e349325e?pvs=25"
              >
                Editing your profile
              </a>
              <a
                className="block"
                target="_blank"
                rel="noopener noreferrer"
                href="https://midi-morocco-1f2.notion.site/Cancellations-449d3ae84fb84cad8a35281183590b51?pvs=25"
              >
                Cancellations
              </a>
              <a
                className="block"
                target="_blank"
                rel="noopener noreferrer"
                href="https://midi-morocco-1f2.notion.site/Statistics-and-data-572cf0cea4da4e949d6956291d75a661?pvs=25"
              >
                Statistics and data
              </a>
              <a
                className="block"
                target="_blank"
                rel="noopener noreferrer"
                href="https://midi-morocco-1f2.notion.site/Reposting-content-e325cbbce4b84a10a0bfbd1c6f1d13c8?pvs=25"
              >
                Reposting content
              </a>
            </div>
          </div>

          <div className="col-span-full">
            <h2 className="text-white text-xl">New booking requests</h2>
            <div className="grid grid-cols-1 md:grid-cols-6 gap-6 mt-3 md:mt-6">
              {pendingRequests.map((booking) => (
                <RequestCard
                  key={booking.id}
                  booking={booking}
                  onSelect={() =>
                    booking.is_blurred
                      ? null
                      : setActiveRequestId(booking.request_id)
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <RequestDrawer
        bookingId={activeRequestId}
        onClose={() => setActiveRequestId(undefined)}
        multipeIds={
          activeRequestId && pendingRequestIds.includes(activeRequestId)
            ? pendingRequestIds
            : undefined
        }
        onChangeId={setActiveRequestId}
      />
    </>
  );
};
