import dayjs from "dayjs";
import pluralize from "pluralize";
import { BookingRequest } from "../../types/booking";
import { useMemo } from "react";
import { useGetBookingRequestedTime } from "../../hooks/custom/useGetBookingRequestedTime";

interface Props {
  booking: BookingRequest;
  onSelect: () => void;
}

export const RequestCard = ({ booking, onSelect }: Props) => {
  const requestedTime = useGetBookingRequestedTime(booking);

  return (
    <button
      type="button"
      className={`p-4 border border-ms-lt-gray border-opacity-25 rounded-xl space-y-2 text-white text-sm ${
        booking.is_blurred ? "blur-sm" : ""
      }`}
      onClick={onSelect}
    >
      <div className="flex">
        <img
          src={
            booking?.influencer?.photo_url
              ? booking.influencer.photo_url.replace(
                  "public",
                  "fit=crop,width=128,height=128,gravity=auto"
                )
              : ""
          }
          className="w-8 h-8 rounded-full"
        />
        <div className="ml-4 text-left">
          <p className="text-white text-sm">{booking.influencer.first_name}</p>
          <p className="text-white text-sm">{booking.influencer.last_name}</p>
        </div>
      </div>
      <div className="space-y-1 text-left">
        <p className="text-white font-medium text-sm">{booking.venue.name}</p>
        <p className="text-white text-sm font-light">
          {dayjs(booking.date).format("dddd, MMM DD")}
        </p>
        <p className="text-white text-sm font-light">{`${
          booking.guests ?? 0
        } ${pluralize("guests", booking.guests ?? 0)}`}</p>
        <p className="text-white text-sm font-light">{requestedTime}</p>
      </div>
    </button>
  );
};
