import { BookingStatus, prettyBookingStatus } from "../../transforms/status";
import { Booking } from "../../types/booking";

interface Props {
  status: Booking["status"];
}

const statusBgMap = {
  [BookingStatus.BOOKING_PENDING]: "border-indigo-500",
  [BookingStatus.BOOKING_VENUE_ACCEPTS]: "border-indigo-500",
  [BookingStatus.BOOKING_VENUE_ALTERNATIVE]: "border-indigo-500",
  [BookingStatus.BOOKING_VENUE_DECLINED]: "border-indigo-700",
  [BookingStatus.BOOKING_INFLUENCER_DECLINED]: "border-indigo-700",
  [BookingStatus.BOOKING_INFLUENCER_CONFIRMS]: "border-green-600",
  [BookingStatus.BOOKING_INFLUENCER_CANCELLED]: "border-red-700",
  [BookingStatus.BOOKING_VENUE_CANCELLED]: "border-red-700",
  [BookingStatus.BOOKING_COMPLETED]: "border-green-900",
};

const statusTextMap = {
  [BookingStatus.BOOKING_PENDING]: "text-indigo-500",
  [BookingStatus.BOOKING_VENUE_ACCEPTS]: "text-indigo-500",
  [BookingStatus.BOOKING_VENUE_ALTERNATIVE]: "text-indigo-500",
  [BookingStatus.BOOKING_VENUE_DECLINED]: "text-indigo-700",
  [BookingStatus.BOOKING_INFLUENCER_DECLINED]: "text-indigo-700",
  [BookingStatus.BOOKING_INFLUENCER_CONFIRMS]: "text-green-600",
  [BookingStatus.BOOKING_INFLUENCER_CANCELLED]: "text-red-700",
  [BookingStatus.BOOKING_VENUE_CANCELLED]: "text-red-700",
  [BookingStatus.BOOKING_COMPLETED]: "text-green-900",
};

export const StatusBadge = ({ status }: Props) => {
  return (
    <div className="w-min">
      <div className={`px-4 py-1 rounded-full border ${statusBgMap[status]}`}>
        <span className={`text-xs font-medium ${statusTextMap[status]}`}>
          {prettyBookingStatus[status]}
        </span>
      </div>
    </div>
  );
};
