import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Calendar } from "../../types/venue";

const handleUpdateHours = async (
  id: string,
  hours: Calendar
): Promise<boolean> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/venues/${id}/hours`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(hours),
    }
  );

  if (!response.ok) {
    throw new Error("Unable to acknowledge the venue");
  }

  return true;
};

export const useUpdateHours = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, hours }: { id: string; hours: Calendar }) =>
      handleUpdateHours(id, hours),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["venue-hours"]);
      },
    }
  );
};
