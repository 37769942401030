import pluralize from "pluralize";
import { Booking, BookingRequest } from "../../types/booking";
import dayjs from "dayjs";
import { StatusBadge } from "./StatusBadge";
import { useMemo } from "react";

interface Props {
  booking: Booking | BookingRequest;
  onSelect: (id: string) => void;
}

export const TableRow = ({ booking, onSelect }: Props) => {
  const time = useMemo(() => {
    if (booking.confirmed_time) return booking.confirmed_time;

    return (
      booking?.requested_dinner ||
      booking?.requested_lunch ||
      booking?.requested_breakfast ||
      booking?.requested_late
    );
  }, [booking]);
  const isBlurred = "is_blurred" in booking && booking.is_blurred;

  return (
    <tr
      onClick={() => (isBlurred ? null : onSelect(booking.request_id))}
      className="cursor-pointer"
    >
      <td className="whitespace-nowrap py-4 text-sm text-white sm:pl-0">
        <p className={`pl-2 ${isBlurred ? "blur-sm" : ""}`}>
          {booking.venue.name}
        </p>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
        <p className={`${isBlurred ? "blur-sm" : ""}`}>
          {`${booking.influencer.first_name} ${booking.influencer.last_name}`}
        </p>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
        <p className={`${isBlurred ? "blur-sm" : ""}`}>
          {`${booking.guests ?? 0} ${pluralize("guests", booking.guests ?? 0)}`}
        </p>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
        <p className={`${isBlurred ? "blur-sm" : ""}`}>
          {dayjs(booking.date).format("MMM DD")}
        </p>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
        <p className={`${isBlurred ? "blur-sm" : ""}`}>{time}</p>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
        <StatusBadge status={booking.status} />
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
        <p
          className={`text-white hover:text-gray-100 pr-2 ${
            isBlurred ? "blur-sm" : ""
          }`}
        >
          View
        </p>
      </td>
    </tr>
  );
};
