import { useRecoilState, useRecoilValue } from "recoil";
import { idTokenState } from "../../atoms/auth";
import { useStytch } from "@stytch/react";
import { useCallback, useEffect } from "react";
import { defer } from "lodash";

export const useGetAuth = () => {
  const [idToken, setIdToken] = useRecoilState(idTokenState);
  const stytchClient = useStytch();

  const updateToken = useCallback(() => {
    const token = stytchClient.session.getTokens()?.session_jwt;
    setIdToken(token);
  }, [stytchClient]);

  useEffect(() => {
    const unsubscribe = stytchClient.session.onChange(updateToken);

    return () => {
      unsubscribe();
    };
  }, [stytchClient.session]);

  useEffect(() => {
    defer(updateToken);
  }, []);

  return {
    idToken,
    isLoading: false,
  };
};
