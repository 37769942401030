import { AnimatePresence, motion } from "framer-motion";
import { Portal } from "react-portal";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Preview } from "./Preview";
import { useGetVenue } from "../../hooks/queries/useGetVenue";
import { useState } from "react";
import { Hours } from "./Hours";
import { Faqs } from "./Faqs";
import { Team } from "./Team";

type SelectedTab = "preview" | "hours" | "faq" | "team" | "preferences";

interface TabProps {
  isSelected: boolean;
  children: string;
  onChange: () => void;
}

const Tab = ({ isSelected, children, onChange }: TabProps) => (
  <button
    type="button"
    onClick={onChange}
    className={`${
      isSelected
        ? "text-ms-primary border-ms-primary"
        : "text-white border-transparent"
    } text-sm border-b-2  pb-2`}
  >
    {children}
  </button>
);

interface Props {
  venueId: string | undefined;
  onClose: () => void;
}

export const VenueDrawer = ({ venueId, onClose }: Props) => {
  const isVisible = !!venueId;
  const { data: venue } = useGetVenue(venueId);

  const [selectedTab, setSelectedTab] = useState<SelectedTab>("preview");

  return (
    <Portal>
      <AnimatePresence>
        {isVisible ? (
          <div className="fixed top-0 left-0 right-0 bottom-0">
            <motion.button
              onClick={onClose}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute top-0 left-0 right-0 bottom-0 z-20 bg-black bg-opacity-25"
            />

            <motion.div
              initial={{ x: 1000 }}
              animate={{ x: isVisible ? 0 : 1000 }}
              exit={{ x: 1000 }}
              transition={{
                type: "tween",
                duration: 0.4,
              }}
              className="bg-ms-dk-gray  py-8 px-6 absolute bottom-0 top-0 right-0 w-full max-w-3xl mx-auto z-50 flex flex-col"
            >
              <button onClick={onClose} className="flex items-center mb-4">
                <ChevronLeftIcon className="h-4 w-4 text-white mr-4" />
                <img
                  src={
                    venue?.logo_url
                      ? venue.logo_url.replace(
                          "public",
                          "fit=crop,width=120,height=120,gravity=auto"
                        )
                      : ""
                  }
                  alt=""
                  className="h-6 w-6 rounded-full mr-4"
                />
                <h2 className="text-white text-xl">{venue?.name}</h2>
              </button>

              <div className="w-full border-b border-gray-200 border-opacity-10 flex items-center justify-center pb-2 mt-4">
                <div className="flex space-x-8 mx-auto">
                  <Tab
                    isSelected={selectedTab === "preview"}
                    onChange={() => setSelectedTab("preview")}
                  >
                    Your listing
                  </Tab>
                  <Tab
                    isSelected={selectedTab === "hours"}
                    onChange={() => setSelectedTab("hours")}
                  >
                    Hours
                  </Tab>
                  <Tab
                    isSelected={selectedTab === "faq"}
                    onChange={() => setSelectedTab("faq")}
                  >
                    FAQ
                  </Tab>
                  <Tab
                    isSelected={selectedTab === "team"}
                    onChange={() => setSelectedTab("team")}
                  >
                    Staff
                  </Tab>
                  <Tab
                    isSelected={selectedTab === "preferences"}
                    onChange={() => setSelectedTab("preferences")}
                  >
                    Preferences
                  </Tab>
                </div>
              </div>
              <div className="flex-1 relative">
                <div className="absolute top-0 left-0 right-0 bottom-0 overflow-y-auto no-scrollbar">
                  {selectedTab === "preview" ? <Preview venue={venue} /> : null}
                  {selectedTab === "hours" ? <Hours venue={venue} /> : null}
                  {selectedTab === "faq" ? <Faqs venue={venue} /> : null}
                  {selectedTab === "team" ? <Team venue={venue} /> : null}
                </div>
              </div>
            </motion.div>
          </div>
        ) : null}
      </AnimatePresence>
    </Portal>
  );
};
