import {last} from 'lodash';

export const cleanWebsite = (website: string) => {
  let clean = website
    .replace('https://', '')
    .replace('http://', '')
    .replace('www.', '');

  if (last(clean) === '/') {
    clean = clean.slice(0, clean.length - 1);
  }

  return clean;
};

export const cleanInstagram = (handle: string) => {
  let clean = handle;

  if (clean.includes('instagram')) {
    clean = last(handle.split('/').filter(Boolean)) ?? handle;
  }

  return `@${clean.replace('@', '')}`;
};

export const cleanInstagramLink = (handle: string) => {
  const cleanHandle = cleanInstagram(handle);
  return `https://instagram.com/${cleanHandle.replace('@', '')}`;
};

export const cleanTiktok = (handle: string) => {
  let clean = handle;

  if (clean.includes('tiktok')) {
    clean = last(handle.split('/').filter(Boolean)) ?? handle;
  }

  return `@${clean.replace('@', '')}`;
};

export const cleanTiktokLink = (handle: string) => {
  const cleanHandle = cleanTiktok(handle);
  return `https://tiktok.com/${cleanTiktok(cleanHandle)}`;
};
