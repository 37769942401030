import { useMemo, useState } from "react";
import { ReservationsTable } from "../../components/ReservationsTable";
import { VenueTabs } from "../../components/VenueTabs";
import { SelectView, ViewOption, ViewOptions } from "./SelectView";
import { RequestDrawer } from "../../components/RequestDrawer";

import { RequestCalendar } from "../../components/RequestCalendar";
import { useGetCurrentBookings } from "../../hooks/queries/useGetCurrentBookings";
import { useGetRequests } from "../../hooks/queries/useGetRequests";
import { chain, sortBy } from "lodash";
import dayjs from "dayjs";
import { VenueChoice } from "../../components/VenueChoice";
import { useGetPastBookings } from "../../hooks/queries/useGetPastBookings";
import { useLocalStorage } from "@uidotdev/usehooks";

export const Schedule = () => {
  const { data: currentBookings = [] } = useGetCurrentBookings();
  const { data: pendingRequests = [] } = useGetRequests();
  const { data: pastBookings = [] } = useGetPastBookings();

  const [selectedView, setSelectedView] = useState<ViewOption>(ViewOptions[0]);
  const [selectedVenue, setSelectedVenue] = useState("*");
  const [activeRequestId, setActiveRequestId] = useState<string | undefined>(
    undefined
  );
  const [isUpcomingOnly, setUpcomingOnly] = useLocalStorage(
    "upcoming-only",
    true
  );

  const activeRequest = useMemo(() => {
    return activeRequestId ? {} : null;
  }, [activeRequestId]);

  const shownBookings = useMemo(() => {
    return chain([
      ...currentBookings,
      ...pendingRequests,
      ...(isUpcomingOnly ? [] : pastBookings),
    ])
      .filter((booking) =>
        selectedVenue === "*" ? true : booking.venue_id === selectedVenue
      )
      .sortBy((booking) => dayjs(booking.date).unix())
      .value();
  }, [
    currentBookings,
    pendingRequests,
    pastBookings,
    isUpcomingOnly,
    selectedVenue,
  ]);

  return (
    <>
      <div className="px-4 md:px-16 py-12">
        <div className="grid grid-cols-1 md:grid-cols-9 gap-6 md:gap-12">
          <div className="col-span-1 hidden md:block">
            <SelectView onSelect={setSelectedView} />
          </div>
          <div className="col-span-7 -mt-2">
            {/* <VenueTabs /> */}
            <VenueChoice selected={selectedVenue} onSelect={setSelectedVenue} />
          </div>
          <div className="col-span-1">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center w-full">
                <input
                  checked={isUpcomingOnly}
                  onChange={() => setUpcomingOnly(!isUpcomingOnly)}
                  type="checkbox"
                  className="h-4 w-4 rounded text-ms-primary focus:ring-ms-primary ring-0 focus:ring-0 bg-ms-md-gray"
                />
                <label
                  htmlFor="comments"
                  className="font-normal ml-3 text-sm flex-1 text-ms-white"
                >
                  Upcoming only
                </label>
              </div>
            </div>
          </div>
        </div>

        <div>
          {selectedView === "list" ? (
            <ReservationsTable
              bookings={shownBookings}
              onSelect={setActiveRequestId}
            />
          ) : null}
          {selectedView === "calendar" ? (
            <RequestCalendar
              bookings={shownBookings}
              onSelect={setActiveRequestId}
            />
          ) : null}
        </div>
      </div>

      <RequestDrawer
        bookingId={activeRequestId}
        onClose={() => setActiveRequestId(undefined)}
      />
    </>
  );
};
