import { useMutation, useQueryClient } from "@tanstack/react-query";

const handleAcknowledgeVenue = async (id: string): Promise<boolean> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/venues/${id}/acknowledgement`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Unable to acknowledge the venue");
  }

  return true;
};

export const useAcknowledgeVenue = () => {
  const queryClient = useQueryClient();

  return useMutation(({ id }: { id: string }) => handleAcknowledgeVenue(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["acknowledgements"]);
    },
  });
};
