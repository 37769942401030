import { useQuery } from "@tanstack/react-query";
import { Faq, Venue } from "../../types/venue";

export const fetchVenueFaqs = async (id: string): Promise<Faq[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/venues/${id}/faqs`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch venue by id");
  }

  const data = await response.json();
  return data.venue_faqs;
};

export const useGetVenueFaqs = (id?: string) => {
  return useQuery(["venue-faqs", id], () => fetchVenueFaqs(id!), {
    enabled: !!id,
  });
};
