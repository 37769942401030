import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/solid";
import { useStytch, useStytchSession, useStytchUser } from "@stytch/react";
import { NavLink } from "react-router-dom";

export const Header = () => {
  const { session } = useStytch();
  return (
    <div className="h-20 w-full bg-ms-black border-slate-300 border-b border-opacity-10 flex items-center justify-between px-4 md:px-8">
      <img src="/images/ms-icon.svg" className="h-12 w-auto hidden md:block" />
      <div className="flex space-x-6 md:space-x-8">
        <NavLink
          to="/"
          className={({ isActive }) =>
            `${
              isActive
                ? "text-ms-primary  border-ms-primary"
                : "text-white border-transparent"
            } pb-1 border-b-2 text-sm md:text-base`
          }
        >
          Today
        </NavLink>
        <NavLink
          to="/schedule"
          className={({ isActive }) =>
            `${
              isActive
                ? "text-ms-primary  border-ms-primary"
                : "text-white border-transparent"
            } pb-1 border-b-2 text-sm md:text-base`
          }
        >
          Schedule
        </NavLink>
        <NavLink
          to="/venues"
          className={({ isActive }) =>
            `${
              isActive
                ? "text-ms-primary  border-ms-primary"
                : "text-white border-transparent"
            } pb-1 border-b-2 text-sm md:text-base`
          }
        >
          Venues
        </NavLink>
      </div>

      <button
        onClick={() => session.revoke()}
        className="h-12 w-12 rounded-full bg-ms-dk-gray flex items-center justify-center text-white text-sm"
      >
        <ArrowRightOnRectangleIcon className="h-5 w-auto" />
      </button>
    </div>
  );
};
