import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { capitalize } from "lodash";
import { Fragment, useState } from "react";

export const ViewOptions = ["list", "calendar"] as const;
export type ViewOption = (typeof ViewOptions)[number];

interface Props {
  onSelect: (value: ViewOption) => void;
}

export const SelectView = ({ onSelect }: Props) => {
  const [selected, setSelected] = useState<ViewOption>(ViewOptions[0]);

  const handleChange = (value: ViewOption) => {
    setSelected(value);
    onSelect(value);
  };

  return (
    <Listbox value={selected} onChange={handleChange}>
      <div className="relative mt-1">
        <Listbox.Button className="relative text-ms-white text-sm font-light w-full cursor-default rounded-full border border-ms-lt-gray border-opacity-25 bg-transparent py-1 pl-3 pr-10 text-left focus:outline-none">
          <span className="block truncate">{capitalize(selected)}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-ms-dk-gray py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-50">
            {ViewOptions.map((option, index) => (
              <Listbox.Option
                key={index}
                className={({ active }) =>
                  `relative cursor-default select-none text-sm py-2 pl-10 pr-4 ${
                    active ? "text-ms-primary" : "text-ms-lt-gray"
                  }`
                }
                value={option}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium text-ms-primary" : "font-light"
                      }`}
                    >
                      {capitalize(option)}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-ms-primary">
                        <CheckIcon className="h-4 w-4" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
