import { useStytchSession, useStytchUser } from "@stytch/react";
import { useQuery } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import { idTokenState } from "../../atoms/auth";
import { VenueStaffProfile } from "../../types/venue";

const fetchMe = async (idToken: string): Promise<VenueStaffProfile> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/me`, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch user");
  }

  return (await response.json()).user;
};

export const useGetUser = (refetchInterval?: number) => {
  const [idToken, setIdToken] = useRecoilState(idTokenState);

  return useQuery(["me"], () => fetchMe(idToken!), {
    enabled: !!idToken,
    refetchInterval,
    onError: () => {
      setIdToken(undefined);
    },
  });
};
