import dayjs from "dayjs";
import { cleanInstagramLink, cleanTiktokLink } from "../transforms/clean";
import { Booking } from "../types/booking";
import pluralize from "pluralize";

interface InfluencerInfoProps {
  data: Booking | undefined;
}

export const InfluencerInfo = ({ data }: InfluencerInfoProps) => {
  return (
    <div className="bg-ms-dk-gray rounded-xl p-6 mt-6">
      <div className="flex">
        <div className="relative">
          <img
            src={data?.influencer.photo_url ?? ""}
            className="bg-ms-lt-gray h-24 w-24 rounded-full"
          />
          {/* <div className="h-4 w-4 rounded-full absolute bottom-0 right-0 bg-ms-primary"></div> */}
        </div>
        <div className="flex-1 ml-12">
          <h4 className="text-lg text-ms-white">{`${data?.influencer.first_name} ${data?.influencer.last_name}`}</h4>
          <div className="flex space-x-8 mt-4">
            {data?.influencer.instagram_handle ? (
              <div>
                <img src="/images/instagram.svg" className="h-8 w-8" />
                <a
                  href={cleanInstagramLink(data?.influencer.instagram_handle)}
                  className="underline text-ms-white text-sm"
                >
                  Instagram
                </a>
              </div>
            ) : null}
            {data?.influencer.tiktok_handle ? (
              <div>
                <img src="/images/tiktok.svg" className="h-8 w-8" />
                <a
                  href={cleanTiktokLink(data?.influencer.tiktok_handle)}
                  className="underline text-ms-white text-sm"
                >
                  TikTok
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <h2 className="text-xl font-medium text-ms-white mt-8">
        {data?.venue.name}
      </h2>
      <div className="mt-6 space-y-4">
        <div className="flex items-center">
          <div className="w-8">
            <img src="/images/calendar.svg" className="w-5 h-5" />
          </div>
          <div className="flex-1">
            <p className="text-ms-white">
              {dayjs(data?.date).format("dddd, MMMM DD")}
            </p>
          </div>
        </div>
        <div className="flex items-center">
          <div className="w-8">
            <img src="/images/people.svg" className="w-5 h-5" />
          </div>
          <div className="flex-1">
            <p className="text-ms-white">{`${data?.guests} total ${pluralize(
              "guests",
              data?.guests ?? 1
            )}`}</p>
          </div>
        </div>
        <div className="flex items-center">
          <div className="w-8">
            <img src="/images/clock.svg" className="w-5 h-5" />
          </div>
          <div className="flex-1">
            <p className="text-ms-white">
              {[
                data?.requested_breakfast && "Breakfast",
                data?.requested_lunch && "Lunch",
                data?.requested_dinner && "Dinner",
                data?.requested_late && "Late night",
              ]
                .filter(Boolean)
                .join(", ")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
