import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Route, Routes, redirect, useNavigate } from "react-router-dom";
import { Booking } from "./layouts/Booking";
import { Launch } from "./layouts/Launch";
import { Login } from "./layouts/Login";
import { Home } from "./layouts/Home";
import { Header } from "./components/Header";
import { Schedule } from "./layouts/Schedule";
import { Venues } from "./layouts/Venues";
import { StytchProvider, useStytch, useStytchUser } from "@stytch/react";
import { MissingLayout } from "./layouts/404";
import { Authenticate } from "./layouts/Authenticate";
import { RecoilRoot } from "recoil";
import { useGetAuth } from "./hooks/custom/useGetAuth";
import { Toaster } from "react-hot-toast";
import { StytchHeadlessClient } from "@stytch/vanilla-js/dist/index.headless";
import { SessionMgt } from "./utils/SessionMgt";

const stytch = new StytchHeadlessClient(
  process.env.REACT_APP_STYTCH_TOKEN as string
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 300000, // 5 minutes
      cacheTime: 3600000, // 1 hour
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <StytchProvider stytch={stytch}>
        <RecoilRoot>
          <Main />
          <Toaster position="top-right" />
        </RecoilRoot>
      </StytchProvider>
      {/* <Authed /> */}
    </QueryClientProvider>
  );
};

const Authed = () => {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/venues" element={<Venues />} />
      </Routes>
    </div>
  );
};

const RedirectToLogin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("login");
  }, [navigate]);

  return null;
};

const RedirectToHome = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, [navigate]);

  return null;
};

const Main = () => {
  const { user } = useStytchUser();
  useGetAuth();

  if (user) {
    return (
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/venues" element={<Venues />} />
          <Route path="/*" element={<RedirectToHome />} />
        </Routes>
        <SessionMgt />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="authenticate" element={<Authenticate />} />

      <Route path="bookings/:id" element={<Booking />} />
      <Route path="launch/:id" element={<Launch />} />
      <Route path="/*" element={<RedirectToLogin />} />
    </Routes>
  );
};

export default App;
