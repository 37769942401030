import { useMemo } from "react";
import { Booking } from "../../types/booking";
import { getCleanTime } from "../../transforms/time";

export const useGetBookingRequestedTime = (booking: Booking | undefined) => {
  const requestedTime = useMemo(() => {
    if (booking?.requested_dinner) return booking.requested_dinner;
    if (booking?.requested_lunch) return booking.requested_lunch;
    if (booking?.requested_breakfast) return booking.requested_breakfast;
    if (booking?.requested_late) return booking.requested_late;
  }, [booking]);

  return getCleanTime(requestedTime);
};
