import { useStytch } from "@stytch/react";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

type Inputs = {
  email: string;
};

export const Login = () => {
  const [isSent, toggleSent] = useState(false);
  const stytchClient = useStytch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const email = watch("email");

  const onSubmit: SubmitHandler<Inputs> = ({ email }) => {
    toggleSent(true);

    stytchClient.magicLinks.email.loginOrCreate(email, {
      login_magic_link_url: process.env.REACT_APP_AUTH_REDIRECT,
      signup_magic_link_url: process.env.REACT_APP_AUTH_REDIRECT,
      signup_expiration_minutes: 30,
      login_expiration_minutes: 30,
    });
  };

  return (
    <div className="w-screen h-screen flex">
      <div className="flex-1 h-full bg-[url(/images/login-bg.jpg)] bg-cover bg-left-bottom relative hidden md:block">
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-25"></div>
        <div className="absolute top-0 left-0 right-0 bottom-0 z-10 flex flex-col items-center justify-center pb-[24vh]">
          <img src="/images/ms-title.svg" className="h-20 w-auto" />
          <p className="text-xl text-white mt-2">Venue Management</p>
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        {!isSent ? (
          <form
            className="flex-1 flex flex-col justify-center items-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            <img src="/images/ms-pfp.png" className="h-20 w-auto" />
            <p className="text-white mt-4">Please log in</p>
            <div className="bg-ms-black border-white border rounded-xl w-80 md:w-96 h-14 mt-4">
              <input
                {...register("email", { required: true })}
                type="text"
                placeholder="someone@example.com"
                className="focus:outline-none focus:ring-0 bg-transparent w-full h-full rounded-xl text-sm text-white"
              />
            </div>
            <button className="mt-4 rounded-full w-80 md:w-96 h-14 bg-ms-primary text-ms-black font-medium text-sm">
              Send login link
            </button>
          </form>
        ) : (
          <div className="flex-1 flex flex-col justify-center items-center">
            <img src="/images/ms-pfp.png" className="h-20 w-auto" />
            <p className="text-white text-center mt-4">Check your email</p>
            <p className="text-white text-center text-sm font-thin mt-4">
              We sent an email with your login link to {email}.
            </p>
            <p className="text-white text-center text-sm font-thin mt-4">
              Wrong email?{" "}
              <button onClick={() => toggleSent(false)} className="underline">
                Go back
              </button>
            </p>
          </div>
        )}
        <div className="py-8 text-center">
          <p className="text-white text-sm">
            Not a Mint Sunday venue?{" "}
            <a href="#" className="underline">
              Click here to get listed
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
