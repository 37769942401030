import { useQuery } from "@tanstack/react-query";
import { Booking } from "../../types/booking";

export const fetchBooking = async (id: string): Promise<Booking> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/bookings/${id}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch booking by id");
  }

  const data = await response.json();
  return data.booking;
};

export const useGetBooking = (id?: string) => {
  return useQuery(["booking", id], () => fetchBooking(id!), {
    enabled: !!id && id !== "new",
  });
};
