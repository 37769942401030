import { Booking } from "../types/booking";
import { Venue } from "../types/venue";

interface VenueListingProps {
  venue: Venue | undefined;
  isShowVenue?: boolean;
}

export const VenueListing = ({ venue, isShowVenue }: VenueListingProps) => {
  return (
    <div className="bg-ms-dk-gray rounded-xl p-4 mt-8">
      {isShowVenue ? (
        <>
          <h1 className="text-xl text-ms-white">{venue?.name}</h1>
          <p className="text-ms-white underline font-light mt-2 mb-8">
            {venue?.address.description}
          </p>
        </>
      ) : null}

      <h2 className="text-xl text-ms-white">Your offer & terms</h2>

      <div className="flex mt-4">
        <div className="w-12 pt-1">
          <img src="/images/tag.svg" className="h-8 w-8" />
        </div>
        <div className="flex-1">
          <p className="text-ms-white text-lg">Offer</p>
          {venue?.offer.offer.split("\n").map((entry) => (
            <p key={entry} className="text-sm text-ms-white">
              {entry}
            </p>
          ))}
        </div>
      </div>
      <h4 className="text-ms-lt-gray text-lg font-bold mt-3 mb-5">For</h4>
      {venue?.offer.instagram_requirements ? (
        <div className="flex mt-4">
          <div className="w-12 pt-1">
            <img src="/images/instagram.svg" className="h-8 w-8" />
          </div>
          <div className="flex-1">
            <p className="text-ms-white text-lg">Instagram</p>
            <ul className="list-disc pl-4 mt-1">
              {venue.offer.instagram_requirements
                ?.split("\n")
                .map((item, index) => (
                  <li key={index} className="text-ms-white text-sm">
                    {item}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      ) : null}

      {venue?.offer.instagram_requirements &&
      venue?.offer.tiktok_requirements ? (
        <h4 className="text-ms-lt-gray text-lg font-bold mt-3 mb-5">Or</h4>
      ) : null}

      {venue?.offer.tiktok_requirements ? (
        <div className="flex mt-4">
          <div className="w-12 pt-1">
            <img src="/images/tiktok.svg" className="h-8 w-8" />
          </div>
          <div className="flex-1">
            <p className="text-ms-white text-lg">Tiktok</p>
            <ul className="list-disc pl-4 mt-1">
              {venue.offer.tiktok_requirements
                ?.split("\n")
                .map((item, index) => (
                  <li key={index} className="text-ms-white text-sm">
                    {item}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};
