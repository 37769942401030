import { atom } from "recoil";

export const idTokenState = atom<string | undefined>({
  key: "id-token",
  default: undefined,
});

export const refreshTokenState = atom<string | undefined>({
  key: "refresh-token",
  default: undefined,
});
