import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Booking } from "../../types/booking";

const handleAcceptRequest = async (
  requestId: string,
  confirmedTime: string
): Promise<Booking> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/bookings/${requestId}/confirm`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        confirmed_time: confirmedTime,
      }),
    }
  );

  if (!response.ok) {
    throw new Error("Unable to accept booking request");
  }

  const data = (await response.json()).booking;
  return data;
};

export const useAcceptRequest = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      requestId,
      confirmedTime,
    }: {
      requestId: string;
      confirmedTime: string;
    }) => handleAcceptRequest(requestId, confirmedTime),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["booking"]);
        queryClient.invalidateQueries(["current-bookings"]);
        queryClient.invalidateQueries(["requested-bookings"]);
      },
    }
  );
};
