import { useForm } from "react-hook-form";
import { useGetVenueFaqs } from "../../hooks/queries/useGetVenueFaqs";
import { Venue } from "../../types/venue";
import { useUpdateFaq } from "../../hooks/mutations/useUpdateFaq";
import toast from "react-hot-toast";

interface ItemProps {
  venueId: string;
  faqId: string;
  question: string;
  answer: string;
}

const FaqItem = ({ venueId, faqId, question, answer }: ItemProps) => {
  const { mutateAsync } = useUpdateFaq();
  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isDirty },
    reset,
  } = useForm<{ question: string; answer: string }>({
    defaultValues: { question, answer },
  });

  const onSubmit = async (data: { question: string; answer: string }) => {
    await mutateAsync({
      venueId,
      faqId,
      ...data,
    });

    toast.success("FAQ succesfully updated");
    reset(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="my-4">
      <h6 className="text-ms-lt-gray text-sm mt-3 mb-2">Question</h6>
      <input
        {...register("question")}
        type="text"
        placeholder="someone@example.com"
        className="focus:outline-none focus:ring-0 bg-transparent border-opacity-25 border-ms-lt-gray w-full h-full rounded-xl text-sm text-white"
      />
      <h6 className="text-ms-lt-gray text-sm mt-3 mb-2">Answer</h6>
      <textarea
        {...register("answer")}
        placeholder="someone@example.com"
        className="focus:outline-none focus:ring-0 bg-transparent border-opacity-25 border-ms-lt-gray w-full h-full rounded-xl text-sm text-white resize-none"
        rows={4}
      />

      <div className="flex flex-row items-center justify-between mt-2">
        <button
          type="button"
          disabled={!isDirty}
          className="px-4 py-2 rounded-lg border border-red-500 text-red-500 text-sm disabled:opacity-25"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={!isDirty}
          className="px-4 py-2 rounded-lg border border-ms-primary text-ms-primary text-sm disabled:opacity-25"
        >
          Update
        </button>
      </div>

      <div className="grid grid-cols-10 gap-4 mt-6 mb-8">
        <div className="col-span-10 border-b border-ms-lt-gray border-opacity-25"></div>
      </div>
    </form>
  );
};

interface Props {
  venue: Venue | undefined;
}

export const Faqs = ({ venue }: Props) => {
  const { data: faqs = [] } = useGetVenueFaqs(venue?.id);

  if (!venue) return null;

  return (
    <div className="w-full max-w-lg mx-auto">
      <div className="pb-3 border-b border-gray-200 border-opacity-10 mt-6 mb-6">
        <h2 className="text-white text-lg">FAQ</h2>
        <div className="text-ms-lt-gray text-xs font-light mt-1">
          Add some frequently asked questions to your listing to let content
          creators know what to expect when they visit you.
        </div>
        <div className="text-ms-lt-gray text-xs font-light mt-2">
          Not sure what to write?{" "}
          <a className="underline">Check out our guide here</a>
        </div>
      </div>

      {faqs.map((faq) => (
        <FaqItem
          key={faq.id}
          venueId={venue.id}
          faqId={faq.id}
          question={faq.question}
          answer={faq.answer}
        />
      ))}
    </div>
  );
};
