import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { CondensedVenue, Venue } from "../../types/venue";

interface Props {
  venue: CondensedVenue;
  onSelect: () => void;
}

export const Card = ({ venue, onSelect }: Props) => {
  return (
    <button
      type="button"
      onClick={onSelect}
      className="w-full h-28 px-4 rounded-xl border border-gray-200 border-opacity-10 flex items-center"
    >
      <img
        src={
          venue?.logo_url
            ? venue.logo_url.replace(
                "public",
                "fit=crop,width=128,height=128,gravity=auto"
              )
            : ""
        }
        alt=""
        className="h-12 w-12 rounded-full"
      />
      <div className="flex-1 mx-4 text-left">
        <h4 className="text-white font-medium">{venue.name}</h4>
        <p className="text-white text-sm">{venue?.address_description}</p>
      </div>
      <ChevronRightIcon className="text-white h-4 w-4" />
    </button>
  );
};
