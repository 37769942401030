import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import { capitalize, range } from "lodash";
import { Fragment, useMemo, useState } from "react";

const availableTimes = range(39).map((index) =>
  dayjs()
    .startOf("day")
    .add(6, "hours")
    .add(30 * index, "minutes")
    .format("h:mm A")
);

interface Props {
  initialTime: string | undefined;
  onSelect: (value: string) => void;
}

export const SelectTime = ({ initialTime, onSelect }: Props) => {
  const [selected, setSelected] = useState<(typeof availableTimes)[number]>(
    availableTimes.find((time) => time === initialTime) || availableTimes[0]
  );

  const handleChange = (value: (typeof availableTimes)[number]) => {
    setSelected(value);
    onSelect(value);
  };

  return (
    <Listbox value={selected} onChange={handleChange}>
      <div className="relative mt-1">
        <Listbox.Button className="relative text-ms-white text-sm font-light w-full cursor-default rounded-xl border border-ms-lt-gray border-opacity-25 bg-transparent py-3 pl-3 pr-10 text-left focus:outline-none">
          <span className="block truncate">{selected}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-ms-dk-gray py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-50">
            {availableTimes.map((option, index) => (
              <Listbox.Option
                key={index}
                className={({ active }) =>
                  `relative cursor-default select-none text-sm py-2 pl-10 pr-4 ${
                    active ? "text-ms-primary" : "text-ms-lt-gray"
                  }`
                }
                value={option}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium text-ms-primary" : "font-light"
                      }`}
                    >
                      {option}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-ms-primary">
                        <CheckIcon className="h-4 w-4" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
