import { useQuery } from "@tanstack/react-query";

export const fetchVenueAcknowledgement = async (id: string): Promise<true> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/venues/${id}/acknowledgement`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch venue acknowledgement by venue id");
  }

  const data = await response.json();
  return data.acknowledged;
};

export const useGetVenueAcknowledgement = (id?: string) => {
  return useQuery(
    ["acknowledgements", id],
    () => fetchVenueAcknowledgement(id!),
    {
      enabled: !!id,
    }
  );
};
