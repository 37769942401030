import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Booking } from "../../types/booking";

const handleRejectRequest = async (requestId: string): Promise<Booking> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/bookings/${requestId}/decline`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Unable to accept booking request");
  }

  const data = (await response.json()).booking;
  return data;
};

export const useRejectRequest = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ requestId }: { requestId: string }) => handleRejectRequest(requestId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["booking"]);
        queryClient.invalidateQueries(["current-bookings"]);
        queryClient.invalidateQueries(["requested-bookings"]);
      },
    }
  );
};
