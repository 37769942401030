import { AnimatePresence, motion } from "framer-motion";
import { Portal } from "react-portal";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { ClipboardIcon, LinkIcon } from "@heroicons/react/24/outline";
import {
  cleanInstagram,
  cleanInstagramLink,
  cleanTiktok,
  cleanTiktokLink,
} from "../../transforms/clean";
import dayjs from "dayjs";
import pluralize from "pluralize";
import isNumber from "lodash/isNumber";
import { useGetBooking } from "../../hooks/queries/useGetBooking";
import { useGetBookingRequestedTime } from "../../hooks/custom/useGetBookingRequestedTime";
import { useEffect, useMemo, useState } from "react";
import { SelectDate } from "./SelectDate";
import { AlternativePopup } from "./AlternativePopup";
import { DeclinePopup } from "./DeclinePopup";
import { CancelPopup } from "./CancelPopup";
import { formatPhoneNumber } from "../../transforms/phone";
import { AcceptButton } from "./AcceptButton";
import { useGetBookingConfirmedTime } from "../../hooks/custom/useGetBookingConfirmedTime";
import { useCopyToClipboard } from "@uidotdev/usehooks";

interface Props {
  bookingId: string | undefined;
  onClose: () => void;
  multipeIds?: string[];
  onChangeId?: (id: string) => void;
}

export const RequestDrawer = ({
  bookingId,
  onClose,
  multipeIds,
  onChangeId,
}: Props) => {
  const isVisible = !!bookingId;
  const { data } = useGetBooking(bookingId);
  const isPending = data?.status === "pending";
  const isAccepted =
    data?.status === "influencer_confirmed" ||
    data?.status === "venue_alternative_proposal";
  const isCompleted = data?.status === "completed";
  const [copiedText, copyToClipboard] = useCopyToClipboard();

  const requestedTime = useGetBookingRequestedTime(data);
  const confirmedTime = useGetBookingConfirmedTime(data);
  const currentMutlipleIndex = useMemo(
    () => multipeIds && bookingId && multipeIds.indexOf(bookingId),
    [multipeIds, bookingId]
  );

  const [isDecline, toggleDecline] = useState(false);
  const [isAlternative, toggleAlternative] = useState(false);
  const [isCancel, toggleCancel] = useState(false);

  useEffect(() => {
    if (isVisible) return;

    toggleDecline(false);
    toggleAlternative(false);
  }, [isVisible]);

  return (
    <Portal>
      <AnimatePresence>
        {isVisible ? (
          <div className="fixed top-0 left-0 right-0 bottom-0">
            <motion.button
              onClick={onClose}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute top-0 left-0 right-0 bottom-0 z-20 bg-black bg-opacity-25"
            />

            <motion.div
              initial={{ x: 1000 }}
              animate={{ x: isVisible ? 0 : 1000 }}
              exit={{ x: 1000 }}
              transition={{
                type: "tween",
                duration: 0.4,
              }}
              className="bg-ms-dk-gray  py-8 px-6 absolute bottom-0 top-0 right-0 w-full md:max-w-xl mx-auto z-50 flex flex-col border-l border-b border-t md:border-t-0 border-r md:border-r-0 border-ms-lt-gray border-opacity-25"
            >
              <button onClick={onClose} className="flex items-center mb-4">
                <ChevronLeftIcon className="h-4 w-4 text-white mr-4" />
                <h2 className="text-white text-xl">Booking request</h2>
              </button>
              <div className="space-y-4 flex-1 relative">
                <div className="absolute top-0 left-0 right-0 bottom-0 overflow-y-auto no-scrollbar">
                  <div className="p-4 mt-4 border border-ms-lt-gray border-opacity-25 rounded-xl text-white text-sm w-full md:w-[340px] mx-auto">
                    <div className="flex">
                      <img
                        src={
                          data?.influencer?.photo_url
                            ? data.influencer.photo_url.replace(
                                "public",
                                "fit=crop,width=128,height=128,gravity=auto"
                              )
                            : ""
                        }
                        className="w-24 h-24 rounded-full"
                      />
                      <div className="flex-1 ml-8">
                        <h4 className="text-lg text-ms-white">{`${data?.influencer.first_name} ${data?.influencer.last_name}`}</h4>
                        <div className="flex space-x-8 mt-2">
                          {data?.influencer.instagram_handle ? (
                            <div className="flex flex-col items-center justify-center space-y-3">
                              <img
                                src="/images/instagram.svg"
                                className="h-6 w-6"
                              />
                              <div className="flex flex-col items-center space-y-2">
                                <a
                                  href={cleanInstagramLink(
                                    data?.influencer.instagram_handle
                                  )}
                                  className="text-ms-white text-xs flex flex-row items-center space-x-1"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <LinkIcon className="h-4 w-4" />
                                  <span>View</span>
                                </a>

                                <button
                                  onClick={() =>
                                    copyToClipboard(
                                      cleanInstagram(
                                        data?.influencer
                                          .instagram_handle as string
                                      )
                                    )
                                  }
                                  className="text-ms-white text-xs font-light flex flex-row items-center space-x-1"
                                >
                                  <ClipboardIcon className="h-4 w-4" />

                                  <span>Copy</span>
                                </button>
                              </div>
                            </div>
                          ) : null}
                          {data?.influencer.tiktok_handle ? (
                            <div className="flex flex-col items-center justify-center space-y-3">
                              <img
                                src="/images/tiktok.svg"
                                className="h-6 w-6"
                              />
                              <div className="flex flex-col items-center space-y-2">
                                <a
                                  href={cleanTiktokLink(
                                    data?.influencer.tiktok_handle
                                  )}
                                  className="text-ms-white text-xs flex flex-row items-center space-x-1"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <LinkIcon className="h-4 w-4" />
                                  <span>View</span>
                                </a>

                                <button
                                  onClick={() =>
                                    copyToClipboard(
                                      cleanTiktok(
                                        data?.influencer.tiktok_handle as string
                                      )
                                    )
                                  }
                                  className="text-ms-white text-xs font-light flex flex-row items-center space-x-1"
                                >
                                  <ClipboardIcon className="h-4 w-4" />

                                  <span>Copy</span>
                                </button>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <h2 className="text-xl font-medium text-ms-white mt-5">
                      {data?.venue.name}
                    </h2>
                    <div className="mt-2 space-y-2">
                      <div className="flex items-center">
                        <div className="w-7">
                          <img src="/images/calendar.svg" className="w-4 h-4" />
                        </div>
                        <div className="flex-1">
                          <p className="text-ms-white text-sm font-light">
                            {dayjs(data?.date).format("dddd, MMMM DD")}
                          </p>
                        </div>
                        <button
                          onClick={() =>
                            copyToClipboard(
                              `Reservation on ${dayjs(data?.date).format(
                                "dddd, MMMM DD"
                              )} (${dayjs(data?.date).format("MM/DD/YY")}) at ${
                                isAccepted ? confirmedTime : requestedTime
                              } for ${data?.guests ?? 0} ${pluralize(
                                "guests",
                                data?.guests ?? 0
                              )}.`
                            )
                          }
                          className="text-ms-white text-xs font-light"
                        >
                          <ClipboardIcon className="h-4 w-4" />
                        </button>
                      </div>
                      <div className="flex items-center">
                        <div className="w-7">
                          <img src="/images/people.svg" className="w-4 h-4" />
                        </div>
                        <div className="flex-1">
                          <p className="text-ms-white text-sm font-light">{`${
                            data?.guests ?? 0
                          } total ${pluralize(
                            "guests",
                            data?.guests ?? 0
                          )}`}</p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="w-7">
                          <img src="/images/clock.svg" className="w-4 h-4" />
                        </div>
                        <div className="flex-1">
                          <p className="text-ms-white text-sm font-light">
                            {isAccepted
                              ? `${confirmedTime} (confirmed)`
                              : requestedTime}
                          </p>
                        </div>
                      </div>
                      {isAccepted ? (
                        <div className="flex items-center">
                          <div className="w-7">
                            <img src="/images/phone.svg" className="w-4 h-4" />
                          </div>
                          <div className="flex-1">
                            <p className="text-ms-white text-sm font-light">
                              {formatPhoneNumber(
                                data?.influencer.phone_number as string,
                                true
                              )}
                            </p>
                          </div>
                          <button
                            onClick={() =>
                              copyToClipboard(
                                formatPhoneNumber(
                                  data?.influencer.phone_number as string,
                                  true
                                ) as string
                              )
                            }
                            className="text-ms-white text-xs font-light"
                          >
                            <ClipboardIcon className="h-4 w-4" />
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="mt-4 p-4 border border-ms-lt-gray border-opacity-25 rounded-xl text-white text-sm w-full md:w-[340px] mx-auto">
                    <h2 className="text-lg text-ms-white">
                      Your offer & terms
                    </h2>

                    <div className="flex mt-4">
                      <div className="w-10 pt-1">
                        <img src="/images/tag.svg" className="h-5 w-5" />
                      </div>
                      <div className="flex-1">
                        <p className="text-ms-white">Offer</p>
                        {data?.venue?.offer.offer.split("\n").map((entry) => (
                          <p
                            key={entry}
                            className="text-sm font-light text-ms-white"
                          >
                            {entry}
                          </p>
                        ))}
                      </div>
                    </div>
                    <h4 className="text-ms-white font-bold mt-3 mb-5">For</h4>
                    {data?.venue?.offer.instagram_requirements ? (
                      <div className="flex mt-4">
                        <div className="w-12 pt-1">
                          <img
                            src="/images/instagram.svg"
                            className="h-8 w-8"
                          />
                        </div>
                        <div className="flex-1">
                          <p className="text-ms-white">Instagram</p>
                          <ul className="list-disc pl-4 mt-1">
                            {data?.venue.offer.instagram_requirements
                              ?.split("\n")
                              .map((item, index) => (
                                <li
                                  key={index}
                                  className="text-ms-white font-light text-sm"
                                >
                                  {item}
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    ) : null}

                    {data?.venue?.offer.instagram_requirements &&
                    data?.venue?.offer.tiktok_requirements ? (
                      <h4 className="text-ms-white font-bold mt-3 mb-5">Or</h4>
                    ) : null}

                    {data?.venue?.offer.tiktok_requirements ? (
                      <div className="flex mt-4">
                        <div className="w-12 pt-1">
                          <img src="/images/tiktok.svg" className="h-8 w-8" />
                        </div>
                        <div className="flex-1">
                          <p className="text-ms-white">Tiktok</p>
                          <ul className="list-disc pl-4 mt-1">
                            {data.venue.offer.tiktok_requirements
                              ?.split("\n")
                              .map((item, index) => (
                                <li
                                  key={index}
                                  className="text-ms-white font-light text-sm"
                                >
                                  {item}
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {isPending ? (
                    <div
                      className={`space-y-4 mt-4 w-full max-w-full md:w-[340px] mx-auto ${
                        multipeIds ? "mb-16" : ""
                      }`}
                    >
                      <AcceptButton booking={data} onSuccess={onClose} />
                      <button
                        onClick={() => toggleAlternative(true)}
                        className="mt-4 rounded-full w-full h-14 bg-transparent border border-ms-primary text-ms-primary font-medium text-sm"
                      >
                        Propose a new time
                      </button>
                      <button
                        type="button"
                        onClick={() => toggleDecline(true)}
                        className="mt-4 rounded-full w-full h-14 bg-transparent text-red-500 font-medium text-sm"
                      >
                        Decline {data?.influencer.first_name}
                      </button>
                    </div>
                  ) : null}

                  {isAccepted ? (
                    <div
                      className={`space-y-4 mt-4 w-full max-w-full md:w-[340px] mx-auto ${
                        multipeIds ? "mb-16" : ""
                      }`}
                    >
                      <button
                        onClick={() => toggleAlternative(true)}
                        className="mt-4 rounded-full w-full h-14 bg-transparent border border-ms-primary text-ms-primary font-medium text-sm"
                      >
                        Modify reservation
                      </button>
                      <button
                        type="button"
                        onClick={() => toggleCancel(true)}
                        className="mt-4 rounded-full w-full h-14 bg-transparent text-red-500 font-medium text-sm"
                      >
                        Cancel reservation
                      </button>
                    </div>
                  ) : null}
                </div>

                {isNumber(currentMutlipleIndex) && multipeIds ? (
                  <div className="absolute -bottom-8 -left-6 -right-6 h-16 bg-ms-dk-gray border-t border-ms-lt-gray border-opacity-25 flex flex-row items-center justify-center space-x-8">
                    <button
                      type="button"
                      className="h-8 w-8"
                      onClick={() => {
                        const prevIndex =
                          currentMutlipleIndex - 1 < 0
                            ? multipeIds.length - 1
                            : currentMutlipleIndex - 1;
                        onChangeId?.(multipeIds[prevIndex]);
                      }}
                    >
                      <img
                        src="/images/chevron-left.png"
                        className="h-full w-full"
                      />
                    </button>
                    <p className="text-white text-lg">{`Booking ${
                      currentMutlipleIndex + 1
                    } of ${multipeIds.length}`}</p>
                    <button
                      type="button"
                      className="h-8 w-8"
                      onClick={() => {
                        const nextIndex =
                          (currentMutlipleIndex + 1) % multipeIds.length;
                        onChangeId?.(multipeIds[nextIndex]);
                      }}
                    >
                      <img
                        src="/images/chevron-right.png"
                        className="h-full w-full"
                      />
                    </button>
                  </div>
                ) : null}

                {data && isAlternative ? (
                  <>
                    <div
                      className="absolute -left-6 -bottom-8 -right-6 -top-8 bg-ms-dk-gray bg-opacity-75 flex items-center justify-center z-10"
                      onClick={() => toggleAlternative(false)}
                    />
                    <div className="absolute -left-6 -bottom-8 -right-6 -top-8 bg-ms-dk-gray bg-opacity-75 flex items-center justify-center">
                      <AlternativePopup
                        initialDate={data!.date as string}
                        initialTime={requestedTime!}
                        booking={data}
                        onSuccess={() => toggleAlternative(false)}
                      />
                    </div>
                  </>
                ) : null}

                {data && isDecline ? (
                  <div
                    className="absolute -left-6 -bottom-8 -right-6 -top-8 bg-ms-dk-gray bg-opacity-75 flex items-center justify-center"
                    onClick={() => toggleDecline(false)}
                  >
                    <DeclinePopup
                      booking={data}
                      onSuccess={onClose}
                      onCancel={() => toggleDecline(false)}
                    />
                  </div>
                ) : null}

                {data && isCancel ? (
                  <div
                    className="absolute -left-6 -bottom-8 -right-6 -top-8 bg-ms-dk-gray bg-opacity-75 flex items-center justify-center"
                    onClick={() => toggleCancel(false)}
                  >
                    <CancelPopup
                      booking={data}
                      onSuccess={onClose}
                      onCancel={() => toggleCancel(false)}
                    />
                  </div>
                ) : null}
              </div>
            </motion.div>
          </div>
        ) : null}
      </AnimatePresence>
    </Portal>
  );
};
