import { useMemo } from "react";
import { useGetUser } from "../hooks/queries/useGetUser";
import { sortBy } from "lodash";

interface Props {
  selected: string;
  onSelect: (value: string) => void;
}

export const VenueChoice = ({ selected, onSelect }: Props) => {
  const { data: { venues } = { venues: [] } } = useGetUser();

  const sortedVenues = useMemo(() => sortBy(venues, "name"), [venues]);

  return (
    <div className="flex space-x-8 mt-4">
      <button
        onClick={() => onSelect("*")}
        className={`${
          selected === "*" ? "text-ms-primary" : "text-white"
        } text-xs md:text-sm border-b-2 ${
          selected === "*" ? "border-ms-primary" : "border-transparent"
        } pb-2`}
      >
        All
      </button>

      {sortedVenues.slice(0, 4).map((venue) => (
        <button
          key={venue.id}
          onClick={() => onSelect(venue.id)}
          className={`${
            selected === venue.id ? "text-ms-primary" : "text-white"
          } truncate text-xs md:text-sm border-b-2 ${
            selected === venue.id ? "border-ms-primary" : "border-transparent"
          } pb-2`}
        >
          {venue.name}
        </button>
      ))}
    </div>
  );
};
