import { useRejectRequest } from "../../hooks/mutations/useRejectRequest";
import { Booking } from "../../types/booking";

interface Props {
  booking: Booking;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export const DeclinePopup = ({ booking, onSuccess, onCancel }: Props) => {
  const { mutateAsync: mutateReject } = useRejectRequest();

  const handleDecline = async () => {
    await mutateReject({
      requestId: booking.request_id!,
    });

    onSuccess?.();
  };

  return (
    <div className="bg-ms-dk-gray rounded-xl p-6 border border-ms-lt-gray border-opacity-25 w-80 md:w-96">
      <h4 className="text-white font-medium">
        Are you sure you want to decline?
      </h4>
      <p className="text-white text-sm mt-2 font-light">{`${booking.influencer.first_name} will be blocked from re-booking for 90 days.`}</p>
      <div className="mt-6 space-y-3">
        <button
          type="button"
          className="mt-4 rounded-full w-full h-14 bg-ms-primary text-ms-black font-medium text-sm"
          onClick={onCancel}
        >
          No, don't decline
        </button>
        <button
          type="button"
          className="mt-4 rounded-full w-full h-14 bg-transparent text-red-500 border border-red-500 font-medium text-sm"
          onClick={handleDecline}
        >
          Yes, decline request
        </button>
      </div>
    </div>
  );
};
