import Frame from "react-frame-component";
import { Venue } from "../../types/venue";

interface Props {
  venue: Venue | undefined;
}

export const Preview = ({ venue }: Props) => {
  if (!venue) return null;

  return (
    <div className="w-full max-w-lg mx-auto">
      <div className="pb-3 border-b border-gray-200 border-opacity-10 mt-6">
        <h2 className="text-white text-lg">Live preview</h2>
        <div className="text-ms-lt-gray text-xs font-light mt-1">
          This is how your listing appears to users in the app
        </div>
      </div>

      <div className="flex flex-col justify-center items-center py-4 relative">
        <div className="bg-[url(/images/device.png)] h-[65vh] w-[30.8vh] bg-contain relative">
          <div className="absolute top-[6px] left-[6px] right-[6px] bottom-[6px] rounded-[8%] overflow-x-hidden overflow-y-scroll no-scrollbar z-10 translate-y-0 pb-8">
            <div className="relative">
              <img
                src={
                  venue?.featured_photo_url
                    ? venue.featured_photo_url.replace(
                        "public",
                        "fit=crop,width=320,height=240,gravity=auto"
                      )
                    : ""
                }
                className="w-full aspect-[4/3]"
              />

              <div className="absolute top-0 left-0 right-0 h-24 bg-gradient-to-b from-[rgba(0,0,0,.7)] to-transparent"></div>
            </div>
            <div className="flex mt-4 justify-between px-3">
              <div>
                <h2 className="text-white text-sm">{venue.name}</h2>
                <p className="text-ms-lt-gray text-[10px]">
                  {`${venue.scene} · ${venue.area_description}`}
                </p>
              </div>
              <img
                src={
                  venue?.logo_url
                    ? venue.logo_url.replace(
                        "public",
                        "fit=crop,width=120,height=120,gravity=auto"
                      )
                    : ""
                }
                className="w-6 h-6 rounded-full"
              />
            </div>

            <div className="px-3">
              <div className="p-3 bg-ms-dk-gray rounded-lg mt-4">
                <h4 className="text-[11px] font-medium text-white">
                  Your offer & terms
                </h4>

                <div className="flex mt-2">
                  <div className="w-6 pt-1">
                    <img src="/images/tag.svg" className="h-4 w-4" />
                  </div>
                  <div className="flex-1">
                    <p className="text-ms-white text-[12px]">Offer</p>
                    {venue?.offer.offer.split("\n").map((entry) => (
                      <p key={entry} className="text-[10px] text-ms-white">
                        {entry}
                      </p>
                    ))}
                  </div>
                </div>
                <h4 className="text-ms-lt-gray text-[11px] font-bold mt-2 mb-3">
                  For
                </h4>
                {venue?.offer.instagram_requirements &&
                venue.offer.allow_instagram ? (
                  <div className="flex mt-2">
                    <div className="w-6 pt-1">
                      <img src="/images/instagram.svg" className="h-4 w-4" />
                    </div>
                    <div className="flex-1">
                      <p className="text-ms-white text-[12px]">Instagram</p>
                      <ul className="list-disc pl-4 mt-1">
                        {venue.offer.instagram_requirements
                          ?.split("\n")
                          .map((item, index) => (
                            <li
                              key={index}
                              className="text-ms-white text-[10px]"
                            >
                              {item}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                ) : null}

                {venue?.offer.instagram_requirements &&
                venue?.offer.allow_instagram &&
                venue?.offer.allow_tiktok &&
                venue?.offer.tiktok_requirements ? (
                  <h4 className="text-ms-lt-gray text-[12px] font-bold mt-2 mb-3">
                    Or
                  </h4>
                ) : null}

                {venue?.offer.tiktok_requirements &&
                venue.offer.allow_tiktok ? (
                  <div className="flex mt-2">
                    <div className="w-6 pt-1">
                      <img src="/images/tiktok.svg" className="h-4 w-4" />
                    </div>
                    <div className="flex-1">
                      <p className="text-ms-white text-[12px]">Tiktok</p>
                      <ul className="list-disc pl-4 mt-1">
                        {venue.offer.tiktok_requirements
                          ?.split("\n")
                          .map((item, index) => (
                            <li
                              key={index}
                              className="text-ms-white text-[10px]"
                            >
                              {item}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="w-full h-px bg-gray-200 bg-opacity-5 my-3"></div>

            <div className="px-3">
              <h4 className="text-white text-[11px] font-medium">
                About the venue
              </h4>

              <p className="text-ms-lt-gray font-light text-[8px] mt-1">
                {venue.description}
              </p>

              <div className="flex space-x-2 mt-2">
                <div className="h-12 w-12 rounded-lg bg-ms-dk-gray flex flex-col items-center justify-center">
                  <p className="text-white text-[7px] font-light">Menu</p>
                </div>
                <div className="h-12 w-12 rounded-lg bg-ms-dk-gray flex flex-col items-center justify-center">
                  <p className="text-white text-[7px] font-light">Directions</p>
                </div>
                <div className="h-12 w-12 rounded-lg bg-ms-dk-gray flex flex-col items-center justify-center">
                  <p className="text-white text-[7px] font-light">Website</p>
                </div>
                <div className="h-12 w-12 rounded-lg bg-ms-dk-gray flex flex-col items-center justify-center">
                  <p className="text-white text-[7px] font-light">Instagram</p>
                </div>
              </div>
            </div>

            <div className="w-full h-px bg-gray-200 bg-opacity-5 my-3"></div>

            <div className="px-3">
              <h4 className="text-white text-[11px] font-medium">Address</h4>

              <p className="text-ms-lt-gray font-light text-[8px] mt-1">
                {venue.address.description}
              </p>
            </div>

            <div className="w-full h-px bg-gray-200 bg-opacity-5 my-3"></div>

            <div className="px-3">
              <h4 className="text-white text-[11px] font-medium">Photos</h4>

              <div className="grid grid-cols-3 gap-2 mt-2">
                {venue.photos.map((photo) => (
                  <img
                    key={photo.image_url}
                    src={photo.image_url.replace(
                      "public",

                      "fit=crop,width=240,height=240,gravity=auto"
                    )}
                    alt=""
                    className="rounded"
                  />
                ))}
              </div>
            </div>

            <div className="w-full h-px bg-gray-200 bg-opacity-5 my-3"></div>

            <div className="px-3">
              <h4 className="text-white text-[11px] font-medium">FAQ</h4>

              <div className="space-y-2 mt-2">
                {venue.faqs.map((faq) => (
                  <div key={faq.id}>
                    <h6 className="text-white text-medium text-[10px]">
                      {faq.question}
                    </h6>
                    <p className="text-ms-lt-gray text-[10px] font-light">
                      {faq.answer}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[url(/images/device-pass.png)] h-[65vh] w-[30.8vh] bg-contain absolute top-0 left-0 right-0 bottom-0 mt-4 mx-auto z-20 pointer-events-none" />
      </div>
    </div>
  );
};
