import { useQuery } from "@tanstack/react-query";
import { Venue, VenueStaffProfile } from "../../types/venue";

export const fetchVenueStaff = async (
  id: string
): Promise<VenueStaffProfile[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/venues/${id}/staff`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch venue by id");
  }

  const data = await response.json();
  return data.venue_staff;
};

export const useGetVenueStaff = (id?: string) => {
  return useQuery(["venue-staff", id], () => fetchVenueStaff(id!), {
    enabled: !!id,
  });
};
