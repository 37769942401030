import { useState } from "react";
import { SelectDate } from "./SelectDate";
import { SelectTime } from "./SelectTime";
import { Booking } from "../../types/booking";
import { useAlternativeRequest } from "../../hooks/mutations/useAlternativeRequest";

interface Props {
  booking: Booking;
  initialDate: string;
  initialTime: string;
  onSuccess?: () => void;
}

export const AlternativePopup = ({
  booking,
  initialDate,
  initialTime,
  onSuccess,
}: Props) => {
  const { mutateAsync: mutateAlternative } = useAlternativeRequest();
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [selectedTime, setSelectedTime] = useState(initialTime);

  const handleAlternative = () => {
    mutateAlternative({
      requestId: booking.request_id,
      date: selectedDate,
      confirmedTime: selectedTime,
    });
    onSuccess?.();
  };

  return (
    <div className="bg-ms-dk-gray rounded-xl p-6 border border-ms-lt-gray border-opacity-25 w-80 md:w-96 z-40">
      <h4 className="text-white font-medium">Propose a new time</h4>
      <p className="text-white text-sm mt-2 font-light">{`When would you like to host ${booking.influencer.first_name}?`}</p>
      <div className="mt-6 space-y-3">
        <SelectDate startDate={initialDate} onSelect={setSelectedDate} />
        <SelectTime initialTime={initialTime} onSelect={setSelectedTime} />
      </div>
      <button
        type="button"
        className="mt-4 rounded-full w-full h-14 bg-ms-primary text-ms-black font-medium text-sm"
        onClick={handleAlternative}
      >
        Send invite
      </button>
    </div>
  );
};
