import { Controller, useForm } from "react-hook-form";
import { Calendar, Venue } from "../../types/venue";
import { delay, isEmpty, keys, pick, range } from "lodash";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { SelectList } from "../../components/SelectList";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useGetVenueHours } from "../../hooks/queries/useGetVenueHours";
import { useUpdateHours } from "../../hooks/mutations/useUpdateHours";
import { lookupTimes, militaryTimes } from "../../transforms/time";
import toast from "react-hot-toast";

type Service = "bk" | "lh" | "pe" | "le";

const serviceHours = {
  bk: militaryTimes.slice(0, 10),
  lh: militaryTimes.slice(8, 18),
  pe: militaryTimes.slice(18, 29),
  le: militaryTimes.slice(28, 38),
} as Record<Service, string[]>;

const defaultHours = {} as Calendar;
const days = ["mo", "tu", "we", "th", "fr", "sa", "su"];
const services = ["bk", "lh", "pe", "le"];

for (const day of days) {
  for (const service of services) {
    const key = `${day}_${service}` as keyof Calendar;
    defaultHours[key] = null;
  }
}

interface EntryProps {
  label: string;
  value: string | null;
  onChange: (value: string | null) => void;
  service: Service;
}

const Entry = ({ label, value, onChange, service }: EntryProps) => {
  const hours = useMemo(
    () =>
      serviceHours[service].map((option) => ({
        label: lookupTimes[option],
        value: option,
      })),
    [serviceHours, service]
  );
  const [startTime, setStartTime] = useState<(typeof militaryTimes)[number]>(
    value ? value.split("-")[0] : hours[0].value
  );
  const [endTime, setEndTime] = useState<(typeof militaryTimes)[number]>(
    value ? value.split("-")[1] : hours[1].value
  );

  const handleChange = (startTime: string, endTime: string) => {
    if (!startTime || !endTime) {
      return onChange(null);
    }

    return onChange(`${startTime}-${endTime}`);
  };

  return (
    <div>
      <h6 className="text-ms-lt-gray text-xs mt-3 mb-1">{label}</h6>
      {value ? (
        <div className="grid grid-cols-10 gap-2">
          <div className="col-span-4">
            <SelectList
              defaultValue={startTime}
              options={hours}
              onSelect={(value) => {
                setStartTime(value);
                handleChange(value, endTime);
              }}
            />
          </div>
          <div className="col-span-1 flex items-center justify-center">
            <p className="text-ms-lt-gray text-sm">to</p>
          </div>
          <div className="col-span-4">
            <SelectList
              defaultValue={endTime}
              options={hours}
              onSelect={(value) => {
                setEndTime(value);
                handleChange(startTime, value);
              }}
            />
          </div>
          <div className="col-span-1 flex items-center justify-center">
            <button
              type="button"
              onClick={() => {
                setStartTime(hours[0].value);
                setEndTime(hours[1].value);
                handleChange("", "");
              }}
            >
              <XMarkIcon className="text-red-500 h-4 w-4" />
            </button>
          </div>
        </div>
      ) : (
        <div className="h-8 flex items-center">
          <button
            className="text-ms-primary text-sm font-bold"
            type="button"
            onClick={() => {
              setStartTime(hours[0].value);
              setEndTime(hours[1].value);
              handleChange(hours[0].value, hours[1].value);
            }}
          >
            Add service
          </button>
        </div>
      )}
    </div>
  );
};

interface Props {
  venue: Venue | undefined;
}

export const Hours = ({ venue }: Props) => {
  const { data: hours = {} } = useGetVenueHours(venue?.id);
  const [isReady, toggleReady] = useState(false);
  const { mutateAsync } = useUpdateHours();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<Calendar>({
    defaultValues: defaultHours,
  });

  useEffect(() => {
    if (isEmpty(hours)) return;

    reset(pick(hours, keys(defaultHours)));
    toggleReady(true);
  }, [hours]);

  const onSubmit = async (data: Calendar) => {
    await mutateAsync({
      id: venue!.id,
      hours: data,
    });

    toast.success("Hours successfully updated");
  };

  if (!venue || !isReady) return null;

  return (
    <form className="w-full max-w-lg mx-auto" onSubmit={handleSubmit(onSubmit)}>
      <div className="pb-3 border-b border-gray-200 border-opacity-10 mt-6 mb-6">
        <h2 className="text-white text-lg">Hours</h2>
        <div className="text-ms-lt-gray text-xs font-light mt-1">
          Set the hours you'd like to host guests on Mint Sunday. You can use
          your regular hours or set specific windows where you'd like to host.
        </div>
      </div>

      <div className="my-4">
        <h4 className="text-white text-lg">Monday</h4>
        <Controller
          name="mo_bk"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Breakfast" service="bk" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="mo_lh"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Lunch" service="lh" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="mo_pe"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Dinner" service="pe" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="mo_le"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Late" service="le" {...{ value, onChange }} />
          )}
        />

        <div className="grid grid-cols-10 gap-4 mt-6 mb-8">
          <div className="col-span-9 border-b border-ms-lt-gray border-opacity-25"></div>
        </div>
      </div>
      <div className="my-4">
        <h4 className="text-white text-lg">Tuesday</h4>
        <Controller
          name="tu_bk"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Breakfast" service="bk" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="tu_lh"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Lunch" service="lh" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="tu_pe"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Dinner" service="pe" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="tu_le"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Late" service="le" {...{ value, onChange }} />
          )}
        />
        <div className="grid grid-cols-10 gap-4 mt-6 mb-8">
          <div className="col-span-9 border-b border-ms-lt-gray border-opacity-25"></div>
        </div>
      </div>
      <div className="my-4">
        <h4 className="text-white text-lg">Wednesday</h4>
        <Controller
          name="we_bk"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Breakfast" service="bk" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="we_lh"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Lunch" service="lh" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="we_pe"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Dinner" service="pe" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="we_le"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Late" service="le" {...{ value, onChange }} />
          )}
        />
        <div className="grid grid-cols-10 gap-4 mt-6 mb-8">
          <div className="col-span-9 border-b border-ms-lt-gray border-opacity-25"></div>
        </div>
      </div>
      <div className="my-4">
        <h4 className="text-white text-lg">Thursday</h4>
        <Controller
          name="th_bk"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Breakfast" service="bk" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="th_lh"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Lunch" service="lh" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="th_pe"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Dinner" service="pe" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="th_le"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Late" service="le" {...{ value, onChange }} />
          )}
        />
        <div className="grid grid-cols-10 gap-4 mt-6 mb-8">
          <div className="col-span-9 border-b border-ms-lt-gray border-opacity-25"></div>
        </div>
      </div>
      <div className="my-4">
        <h4 className="text-white text-lg">Friday</h4>
        <Controller
          name="fr_bk"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Breakfast" service="bk" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="fr_lh"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Lunch" service="lh" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="fr_pe"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Dinner" service="pe" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="fr_le"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Late" service="le" {...{ value, onChange }} />
          )}
        />
        <div className="grid grid-cols-10 gap-4 mt-6 mb-8">
          <div className="col-span-9 border-b border-ms-lt-gray border-opacity-25"></div>
        </div>
      </div>
      <div className="my-4">
        <h4 className="text-white text-lg">Saturday</h4>
        <Controller
          name="sa_bk"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Breakfast" service="bk" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="sa_lh"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Lunch" service="lh" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="sa_pe"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Dinner" service="pe" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="sa_le"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Late" service="le" {...{ value, onChange }} />
          )}
        />
        <div className="grid grid-cols-10 gap-4 mt-6 mb-8">
          <div className="col-span-9 border-b border-ms-lt-gray border-opacity-25"></div>
        </div>
      </div>
      <div className="my-4">
        <h4 className="text-white text-lg">Sunday</h4>
        <Controller
          name="su_bk"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Breakfast" service="bk" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="su_lh"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Lunch" service="lh" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="su_pe"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Dinner" service="pe" {...{ value, onChange }} />
          )}
        />
        <Controller
          name="su_le"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Entry label="Late" service="le" {...{ value, onChange }} />
          )}
        />

        <div className="grid grid-cols-10 gap-4 mt-6 mb-8">
          <div className="col-span-9 border-b border-ms-lt-gray border-opacity-25"></div>
        </div>
      </div>

      <button
        type="submit"
        disabled={!isDirty}
        className="mt-4 rounded-full w-full h-14 bg-transparent border border-ms-primary text-ms-primary font-medium text-sm disabled:opacity-25"
      >
        Save hours
      </button>
      <button
        type="button"
        onClick={() => {
          toggleReady(false);
          reset(pick(hours, keys(defaultHours)));
          delay(() => {
            toggleReady(true);
          }, 50);
        }}
        className="mt-4 rounded-full w-full h-14 bg-transparent text-red-500 font-medium text-sm"
      >
        Cancel
      </button>
    </form>
  );
};
