import { useStytch, useStytchSession } from "@stytch/react";
import { useEffect } from "react";

export const SessionMgt = () => {
  const { session } = useStytchSession();
  const {
    session: { authenticate },
  } = useStytch();

  useEffect(() => {
    authenticate({ session_duration_minutes: 60 * 24 * 30 });
  }, []);

  return null;
};
