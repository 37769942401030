import { useQuery } from "@tanstack/react-query";
import { Venue } from "../../types/venue";

export const fetchVenue = async (id: string): Promise<Venue> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/venues/${id}`);
  if (!response.ok) {
    throw new Error("Failed to fetch venue by id");
  }

  const data = await response.json();
  return data.venue;
};

export const useGetVenue = (id?: string) => {
  return useQuery(["venue", id], () => fetchVenue(id!), {
    enabled: !!id && id !== "new",
  });
};
