import { useParams } from "react-router-dom";
import { useGetVenue } from "../../hooks/queries/useGetVenue";
import { VenueListing } from "../../components/VenueListing";
import { useState } from "react";
import { useGetVenueAcknowledgement } from "../../hooks/queries/useGetVenueAcknowledgement";
import { useAcknowledgeVenue } from "../../hooks/mutations/useAcknowledgeVenue";

export const Launch = () => {
  const { id } = useParams();
  const { data } = useGetVenue(id);

  const { data: isAcknowledged } = useGetVenueAcknowledgement(id);
  const { mutateAsync, isLoading } = useAcknowledgeVenue();

  const [isAgree, toggleAgree] = useState(false);
  const [isAuthority, toggleAuthority] = useState(false);

  return (
    <div className="bg-ms-black">
      <div className="w-full max-w-xl mx-auto pt-16 pb-8 px-4 md:px-0">
        <div className="flex items-center justify-center">
          <img src="/images/ms-logo.png" className="w-48 h-auto" />
        </div>

        <div className="h-px w-full my-8 bg-ms-white bg-opacity-10"></div>

        <h2 className="text-xl font-semibold text-ms-white text-center mt-4">
          It's time to list your venue
        </h2>

        <p className="text-ms-white text-center mt-2">
          Click the button below to list your venue on Mint Sunday
        </p>

        <VenueListing venue={data} isShowVenue />

        <div className="space-y-4 my-8">
          <div className="relative flex items-start mt-2">
            <div className="flex h-6 items-center">
              <input
                checked={isAgree}
                onChange={() => toggleAgree(!isAgree)}
                type="checkbox"
                className="h-4 w-4 rounded text-ms-primary focus:ring-ms-primary bg-ms-md-gray"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label
                htmlFor="comments"
                className="font-normal text-sm text-ms-white"
              >
                I agree to Mint Sunday's{" "}
                <a
                  href="https://www.notion.so/Terms-of-Service-671bfb87a7d7433bbda44581c6c63b4b"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://www.notion.so/Privacy-Policy-a536e1b3b03b4ef29679cc3cb874371e"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  Privacy Policy
                </a>
              </label>
            </div>
          </div>
          <div className="relative flex items-start mt-2">
            <div className="flex h-6 items-center">
              <input
                checked={isAuthority}
                onChange={() => toggleAuthority(!isAuthority)}
                type="checkbox"
                className="h-4 w-4 rounded text-ms-primary focus:ring-ms-primary bg-ms-md-gray"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label
                htmlFor="comments"
                className="font-normal text-sm text-ms-white"
              >
                I have the authority to list this venue on Mint Sunday
              </label>
            </div>
          </div>
        </div>

        <button
          onClick={async () => {
            await mutateAsync({
              id: id!,
            });
          }}
          disabled={!isAgree || !isAuthority || isLoading || isAcknowledged}
          className="h-16 w-full rounded-full bg-ms-primary flex items-center justify-center text-ms-black font-semibold disabled:opacity-20"
        >
          {isAcknowledged ? "Already activated!" : "Activate venue"}
        </button>

        <div className="h-px w-full my-8 bg-ms-white bg-opacity-10"></div>

        <div className="flex justify-between">
          <p className="text-sm text-ms-white">
            Need to make changes?{" "}
            <a href="#" className="underline">
              Contact us
            </a>
          </p>

          <img src="/images/help-circle.png" className="h-6 w-6" />
        </div>
      </div>
    </div>
  );
};
