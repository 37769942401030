import { AnimatePresence, motion } from "framer-motion";
import { Booking } from "../../types/booking";
import { Portal } from "react-portal";

interface DeclineTrayProps {
  isVisible: boolean;
  booking: Booking | undefined;
  onClose: () => void;
  onDecline: () => void;
}

export const DeclineTray = ({
  isVisible,
  booking,
  onClose,
  onDecline,
}: DeclineTrayProps) => {
  return (
    <Portal>
      <AnimatePresence>
        {isVisible ? (
          <div className="fixed top-0 left-0 right-0 bottom-0">
            <motion.button
              onClick={onClose}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute top-0 left-0 right-0 bottom-0 z-20 bg-black bg-opacity-25"
            />

            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: isVisible ? 0 : 1000 }}
              exit={{ y: 1000 }}
              drag="y"
              dragConstraints={{ top: 0 }}
              transition={{
                type: "tween",
                duration: 0.4,
              }}
              dragSnapToOrigin={true}
              className="bg-ms-dk-gray rounded-t-3xl py-4 px-4 absolute bottom-0 left-0 right-0 w-full max-w-xl mx-auto z-50"
            >
              <div className="w-full mx-auto h-1 rounded-full max-w-[25%] bg-ms-md-gray mb-8"></div>
              <div className="flex flex-col items-center justify-center">
                <img
                  className="h-16 w-16 rounded-full"
                  src={booking?.influencer.photo_url ?? ""}
                />
                <p className="text-ms-white text-sm font-medium text-center mt-4">{`Are you sure you want to decline ${booking?.influencer.first_name}?`}</p>
                <p className="text-ms-white text-sm font-light text-center mt-2">{`They'll be notified you've declined to host them and will not be able to re-book with you for 30 days.`}</p>
              </div>

              <div className="mt-8 mb-8 space-y-3">
                <button
                  onClick={() => {
                    onDecline();
                    onClose();
                  }}
                  className="h-16 w-full rounded-full bg-ms-red flex items-center justify-center text-ms-black font-semibold"
                >
                  {`Decline ${booking?.influencer.first_name}`}
                </button>
                <button
                  onClick={onClose}
                  className="h-16 w-full rounded-full bg-transparent border border-ms-white flex items-center justify-center text-ms-white font-semibold"
                >
                  Cancel
                </button>
              </div>
            </motion.div>
          </div>
        ) : null}
      </AnimatePresence>
    </Portal>
  );
};
