import dayjs from "dayjs";
import { range } from "lodash";

const TIME_SLOTS = 37;

export const militaryTimes = range(TIME_SLOTS).map((index) =>
  dayjs()
    .startOf("day")
    .add(7, "hours")
    .add(30 * index, "minutes")
    .format("HHmm")
);

export const availableTimes = range(TIME_SLOTS).map((index) =>
  dayjs()
    .startOf("day")
    .add(7, "hours")
    .add(30 * index, "minutes")
    .format("h:mm A")
);

export const lookupTimes = militaryTimes.reduce((pv, i, index) => {
  return {
    ...pv,
    [i]: availableTimes[index],
  };
}, {} as Record<string, string>);

export const getCleanTime = (time: string | null | undefined) =>
  time ? lookupTimes?.[time] ?? time : "";
