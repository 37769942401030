import { useForm } from "react-hook-form";
import { useGetVenueFaqs } from "../../hooks/queries/useGetVenueFaqs";
import { Venue, VenueStaffProfile } from "../../types/venue";
import { useGetVenueStaff } from "../../hooks/queries/useGetVenueStaff";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useMemo } from "react";

interface ProfileProps {
  profile: VenueStaffProfile;
}

const Profile = ({ profile }: ProfileProps) => {
  return (
    <div className="grid grid-cols-10 gap-4 border-b border-ms-lt-gray border-opacity-25 pb-4 mb-3">
      <div className="col-span-4">
        <p className="text-white font-light">{`${profile.first_name} ${profile.last_name}`}</p>
      </div>
      <div className="col-span-4">
        <p className="text-white font-light">{`${profile.email}`}</p>
      </div>
      {!profile.is_owner ? (
        <div className="col-span-2 flex items-end">
          <button className="text-ms-lt-gray text-sm font-light">Remove</button>
        </div>
      ) : null}
    </div>
  );
};

interface Props {
  venue: Venue | undefined;
}

export const Team = ({ venue }: Props) => {
  const { data: team = [] } = useGetVenueStaff(venue?.id);

  const owners = useMemo(
    () => team.filter((member) => member.is_owner),
    [team]
  );
  const staff = useMemo(
    () => team.filter((member) => !member.is_owner),
    [team]
  );

  if (!venue) return null;

  return (
    <div className="w-full max-w-lg mx-auto">
      <div className="pb-3 border-b border-gray-200 border-opacity-10 mt-6 mb-6">
        <h2 className="text-white text-lg">Team</h2>
        <div className="text-ms-lt-gray text-xs font-light mt-1">
          Manage your team at this venue. People who you add to your team will
          be able to accept, decline, and manage booking requests.
        </div>
      </div>

      <div className="py-4 mb-4">
        <h4 className="text-white text-lg mb-4">Owners</h4>
        {owners.map((member) => (
          <Profile key={member.id} profile={member} />
        ))}
      </div>

      <div className="py-4">
        <h4 className="text-white text-lg mb-4">Staff</h4>
        {staff.map((member) => (
          <Profile key={member.id} profile={member} />
        ))}

        <button className="flex flex-row items-center text-ms-lt-gray font-light">
          <PlusIcon className="h-4 w-4" />
          <span className="ml-2 underline">Add staff member</span>
        </button>
      </div>
    </div>
  );
};
