import { useQuery } from "@tanstack/react-query";
import { Booking } from "../../types/booking";
import { sortBy } from "lodash";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { idTokenState } from "../../atoms/auth";

export const fetchCurrentBookings = async (
  idToken: string
): Promise<Booking[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/bookings`, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch bookings");
  }

  const data = await response.json();

  return sortBy(data.bookings as any, (booking) =>
    dayjs(booking.created_at).unix()
  );
};

export const useGetCurrentBookings = () => {
  const idToken = useRecoilValue(idTokenState);
  return useQuery(["current-bookings"], () => fetchCurrentBookings(idToken!), {
    enabled: !!idToken,
  });
};
