import { useParams } from "react-router-dom";
import { useGetBooking } from "../../hooks/queries/useGetBooking";
import { useAcceptRequest } from "../../hooks/mutations/useAcceptRequest";
import { useRejectRequest } from "../../hooks/mutations/useRejectRequest";
import { useMemo, useState } from "react";
import { useAlternativeRequest } from "../../hooks/mutations/useAlternativeRequest";
import { AlternativeTray } from "./AlternativeTray";
import { DeclineTray } from "./DeclineTray";
import { BookingStatus } from "../../transforms/status";
import { some } from "lodash";
import { InfluencerInfo } from "../../components/InfluencerInfo";
import { VenueListing } from "../../components/VenueListing";

export const Booking = () => {
  const { id } = useParams();
  const { data } = useGetBooking(id);

  const [isAlternative, toggleAlternative] = useState(false);
  const [isDecline, toggleDecline] = useState(false);

  const isStatusPending = data?.status === BookingStatus.BOOKING_PENDING;
  const isStatusConfirmed =
    data?.status === BookingStatus.BOOKING_INFLUENCER_CONFIRMS;
  const isStatusDeclined =
    data?.status === BookingStatus.BOOKING_VENUE_DECLINED;
  const isStatusAlternative =
    data?.status === BookingStatus.BOOKING_VENUE_ALTERNATIVE;

  const { mutateAsync: mutateAccept } = useAcceptRequest();
  const { mutateAsync: mutateAlternative } = useAlternativeRequest();
  const { mutateAsync: mutateReject } = useRejectRequest();

  const requestedTime = useMemo(() => {
    if (data?.requested_dinner) return data.requested_dinner;
    if (data?.requested_lunch) return data.requested_lunch;
    if (data?.requested_breakfast) return data.requested_breakfast;
    if (data?.requested_late) return data.requested_late;
  }, [data]);

  if (
    !some([
      isStatusPending,
      isStatusConfirmed,
      isStatusAlternative,
      isStatusDeclined,
    ])
  )
    return null;

  if (isStatusConfirmed) {
    return (
      <div className="bg-ms-black">
        <div className="w-full max-w-xl mx-auto pt-16 pb-8 px-4 md:px-0">
          <div className="flex flex-col justify-center items-center">
            <img src="/images/status-confirmed.png" className="h-12 w-12" />

            <h1 className="text-2xl text-ms-white text-center mt-6">
              Booking confirmed!
            </h1>

            <p className="text-ms-white text-center font-light mt-3">
              View the details of your booking below. Make sure to add it to
              your calendar and inform your waitstaff.
            </p>
          </div>

          <InfluencerInfo data={data} />

          <div className="h-px w-full my-8 bg-ms-white bg-opacity-10"></div>

          <h4 className="text-ms-white text-lg font-medium">{`Before you host ${data.influencer.first_name}`}</h4>
          <p className="text-ms-white">
            Take a moment to read our hosting tips.
          </p>

          <button className="bg-ms-dk-gray p-4 rounded-xl flex items-center w-full mt-6">
            <img src="/images/helper.png" className="h-16 w-16 mr-4" />
            <div className="flex-1 text-left">
              <h6 className="text-ms-white font-medium">
                Read our hosting tips here
              </h6>
              <p className="text-ms-white font-light text-sm">
                Better hosts often see better promotion of their venues
              </p>
            </div>

            <img src="/images/external-link.png" className="ml-8 h-8 w-8" />
          </button>

          <div className="h-px w-full mt-8 mb-6 bg-ms-white bg-opacity-10"></div>

          <div className="flex justify-between">
            <p className="text-sm text-ms-white">
              Made a mistake?{" "}
              <a href="#" className="underline">
                Contact us
              </a>
            </p>

            <img src="/images/help-circle.png" className="h-6 w-6" />
          </div>

          <div className="h-px w-full mt-6 mb-8 bg-ms-white bg-opacity-10"></div>
        </div>
      </div>
    );
  }

  if (isStatusAlternative) {
    return (
      <div className="bg-ms-black">
        <div className="w-full max-w-xl mx-auto pt-16 pb-8 px-4 md:px-0">
          <div className="flex flex-col justify-center items-center">
            <img src="/images/status-alternative.png" className="h-12 w-12" />
            <p className="text-xs font-medium text-ms-yellow text-center mt-2">
              This is not a booking confirmation
            </p>

            <h1 className="text-2xl text-ms-white text-center mt-6">
              Alternative time proposed
            </h1>

            <p className="text-ms-white text-center font-light mt-3">
              {`You've proposed this time to ${data.influencer.first_name}. You'll be notified when they accept or decline the new time.`}
            </p>
          </div>

          <InfluencerInfo data={data} />

          <div className="h-px w-full mt-8 mb-6 bg-ms-white bg-opacity-10"></div>

          <div className="flex justify-between">
            <p className="text-sm text-ms-white">
              Made a mistake?{" "}
              <a href="#" className="underline">
                Contact us
              </a>
            </p>

            <img src="/images/help-circle.png" className="h-6 w-6" />
          </div>

          <div className="h-px w-full mt-6 mb-8 bg-ms-white bg-opacity-10"></div>
        </div>
      </div>
    );
  }

  if (isStatusDeclined) {
    return (
      <div className="bg-ms-black">
        <div className="w-full max-w-xl mx-auto pt-16 pb-8 px-4 md:px-0">
          <div className="flex flex-col justify-center items-center">
            <img src="/images/status-declined.png" className="h-12 w-12" />

            <h1 className="text-2xl text-ms-white text-center mt-6">
              {`You declined ${data?.influencer.first_name}`}
            </h1>

            <p className="text-ms-white text-center font-light mt-3">
              They'll be notified that you've chosen not to host them.
            </p>
          </div>

          <InfluencerInfo data={data} />

          <div className="h-px w-full mt-8 mb-6 bg-ms-white bg-opacity-10"></div>

          <div className="flex justify-between">
            <p className="text-sm text-ms-white">
              Made a mistake?{" "}
              <a href="#" className="underline">
                Contact us
              </a>
            </p>

            <img src="/images/help-circle.png" className="h-6 w-6" />
          </div>

          <div className="h-px w-full mt-6 mb-8 bg-ms-white bg-opacity-10"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-ms-black">
        <div className="w-full max-w-xl mx-auto pt-16 pb-8 px-4 md:px-0">
          <h1 className="text-2xl text-ms-white">
            {`${data?.influencer.first_name} would like to visit ${data?.venue.name}`}
          </h1>

          <InfluencerInfo data={data} />

          <div className="h-px w-full my-8 bg-ms-white bg-opacity-10"></div>

          <h2 className="text-xl font-semibold text-ms-white mt-4">
            {`Choose a time to host ${data?.influencer.first_name}`}
          </h2>

          <p className="text-ms-white mt-2">
            They'll receive an invitation to visit you at the time you select.
          </p>

          <h4 className="text-lg mt-4 font-semibold text-ms-white">
            Requested time
          </h4>

          <div className="flex mt-2">
            <div className="w-8">
              <img src="/images/clock.svg" className="w-6 h-6" />
            </div>
            <div className="flex-1">
              <p className="text-ms-white">
                {[
                  data?.requested_breakfast && "Breakfast",
                  data?.requested_lunch && "Lunch",
                  data?.requested_dinner && "Dinner",
                  data?.requested_late && "Late night",
                ]
                  .filter(Boolean)
                  .join(", ")}
              </p>
            </div>
          </div>

          <div className="flex flex-wrap mt-8">
            <div className="bg-ms-dk-gray py-3 px-2 text-ms-white mr-2 mb-2 rounded">
              {requestedTime}
            </div>
          </div>

          <div className="h-px w-full my-8 bg-ms-white bg-opacity-10"></div>

          <VenueListing venue={data?.venue} />

          <p className="text-ms-white text-sm mt-8">
            {`${data?.influencer.first_name} will be responsible for posting content within 48 hours of
          visiting you.`}
          </p>

          <div className="h-px w-full my-8 bg-ms-white bg-opacity-10"></div>

          <div className="flex justify-between">
            <p className="text-sm text-ms-white">
              Need help?{" "}
              <a href="#" className="underline">
                Contact us
              </a>
            </p>

            <img src="/images/help-circle.png" className="h-6 w-6" />
          </div>
        </div>

        <div className="w-full bg-ms-dk-gray">
          <div className="w-full max-w-xl mx-auto py-8 px-4 md:px-0">
            <div className="space-y-4">
              <button
                onClick={() =>
                  mutateAccept({
                    requestId: id!,
                    confirmedTime: requestedTime!,
                  })
                }
                className="h-16 w-full rounded-full bg-ms-primary flex items-center justify-center text-ms-black font-semibold"
              >
                {`Invite ${data?.influencer.first_name}`}
              </button>
              <button
                onClick={() => toggleAlternative(true)}
                className="h-16 w-full rounded-full bg-ms-md-gray flex items-center justify-center text-ms-white font-semibold"
              >
                Propose a different time
              </button>
              <button
                onClick={() => toggleDecline(true)}
                className="h-16 w-full rounded-full bg-transparent text-red-500 flex items-center justify-center font-semibold"
              >
                {`Decline ${data?.influencer.first_name}`}
              </button>
            </div>
          </div>
        </div>
      </div>

      <AlternativeTray
        isVisible={isAlternative}
        booking={data}
        onClose={() => toggleAlternative(false)}
        onPropose={(time: string) => {
          mutateAlternative({
            requestId: id!,
            confirmedTime: time,
          });
        }}
      />

      <DeclineTray
        isVisible={isDecline}
        booking={data}
        onClose={() => toggleDecline(false)}
        onDecline={() => {
          mutateReject({
            requestId: id!,
          });
        }}
      />
    </>
  );
};
