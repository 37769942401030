import { useReactTable } from "@tanstack/react-table";
import dayjs from "dayjs";
import pluralize from "pluralize";
import { Booking } from "../../types/booking";
import { StatusBadge } from "./StatusBadge";
import { TableRow } from "./TableRow";

interface Props {
  bookings: Booking[];
  onSelect: (id: string) => void;
  includeStatus?: boolean;
}

export const ReservationsTable = ({
  bookings,
  onSelect,
  includeStatus,
}: Props) => {
  return (
    <div className="">
      <div className="mt-8 flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 text-left text-xs font-thin text-white pl-2"
                  >
                    Venue
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-thin text-white"
                  >
                    Guest
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-thin text-white"
                  >
                    Party size
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-thin text-white"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-thin text-white"
                  >
                    Time
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-thin text-white"
                  >
                    Status
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 divide-opacity-25">
                {bookings.map((booking) => (
                  <TableRow
                    key={booking.request_id}
                    {...{ booking, onSelect }}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
