export const BookingStatus = {
  BOOKING_PENDING: "pending",
  BOOKING_VENUE_DECLINED: "venue_declined",
  BOOKING_VENUE_ACCEPTS: "venue_accepts",
  BOOKING_VENUE_ALTERNATIVE: "venue_alternative_proposal",
  BOOKING_INFLUENCER_CONFIRMS: "influencer_confirmed",
  BOOKING_INFLUENCER_DECLINED: "influencer_declined",
  BOOKING_COMPLETED: "completed",
  BOOKING_VENUE_CANCELLED: "venue_cancelled",
  BOOKING_INFLUENCER_CANCELLED: "influencer_cancelled",
} as const;

export type BookingStatusType =
  (typeof BookingStatus)[keyof typeof BookingStatus];

export const prettyBookingStatus = {
  [BookingStatus.BOOKING_PENDING]: "Pending",
  [BookingStatus.BOOKING_VENUE_ACCEPTS]: "Venue accepted",
  [BookingStatus.BOOKING_VENUE_ALTERNATIVE]: "Venue alternative proposed",
  [BookingStatus.BOOKING_VENUE_DECLINED]: "Venue declined",
  [BookingStatus.BOOKING_INFLUENCER_DECLINED]: "Influencer declined",
  [BookingStatus.BOOKING_INFLUENCER_CONFIRMS]: "Confirmed",
  [BookingStatus.BOOKING_INFLUENCER_CANCELLED]: "Influencer cancelled",
  [BookingStatus.BOOKING_VENUE_CANCELLED]: "Venue cancelled",
  [BookingStatus.BOOKING_COMPLETED]: "Completed",
};
