import React, { useEffect } from "react";
import { useStytch, useStytchSession } from "@stytch/react";

export const Authenticate = () => {
  const stytchClient = useStytch();
  const { session } = useStytchSession();

  useEffect(() => {
    if (session) {
      // window.location.href = 'https://example.com/profile';
    } else {
      const token = new URLSearchParams(window.location.search).get("token");
      if (!token) return;

      stytchClient.magicLinks.authenticate(token, {
        session_duration_minutes: 60 * 24 * 7,
      });
    }
  }, [stytchClient, session]);

  return <div>Loading</div>;
};
